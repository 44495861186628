/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum WorksheetErrorType {
    MORE_THAN_ONE_PAGE = 'more_than_one_page',
    NO_QUESTION = 'no_question',
    NO_TITLE = 'no_title',
    UNEXPECTED_ERROR = 'unexpected_error',
}
