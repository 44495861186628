// src/pages/SnapUpload.tsx
import * as sentry_sdk from '@sentry/react';
import { AlertCircle, Camera, LoaderIcon } from 'lucide-react'; // Import Camera icon
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import { SnapErrorType, SnapSchema, SnapState } from '../api';
import SnapResultsMessages from '../components/features/snaps/SnapResultsMessages';
import SnapResultsTable from '../components/features/snaps/SnapResultsTable';
import { Alert, AlertDescription, AlertTitle } from '../components/ui/alert';
import getApiService from '../services/api';

export const snapLoader = async ({ params }: LoaderFunctionArgs) => {
  const api = await getApiService();
  if (!api) return;
  const { id } = params;
  if (!id) {
    throw new Error('Snap ID is missing.');
  }

  const snap = await api.appApiSnapsGetSnap(id);
  return snap;
};

const SnapUpload = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [snap, setSnap] = useState<SnapSchema>(useLoaderData() as SnapSchema);
  const [isUploading, setIsUploading] = useState(false);

  if (!snap) {
    navigate('/');
    return <p>Redirecting...</p>;
  }

  const uploadSnap = async (file: File) => {
    const api = await getApiService();
    if (!api) return;

    const updatedSnap = await api.appApiSnapsUploadSnap(snap.id, {
      image: file,
    });
    setSnap(updatedSnap);
    setIsUploading(false);
  };

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    setIsUploading(true);
    const file = e.target.files[0];
    uploadSnap(file);
  };

  const refreshSnap = async () => {
    const api = await getApiService();
    if (!api) return;

    const updatedSnap = await api.appApiSnapsGetSnap(snap.id);
    setSnap(updatedSnap);
  };

  useEffect(() => {
    if (isProcessing(snap.state)) {
      const interval = setInterval(() => {
        refreshSnap();
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [snap.state]);

  const isProcessing = (snapState: SnapState) => {
    return (
      isUploading ||
      ![SnapState.CREATED, SnapState.COMPLETED, SnapState.ERROR].includes(
        snap.state
      )
    );
  };

  const getErrorMessage = (error_type: SnapErrorType | null | undefined) => {
    switch (error_type) {
      case SnapErrorType.MISSING_MARKERS:
        return t('snap.uploadFailed.missingMarkers');
      case SnapErrorType.INVALID_QR_CODE:
        return t('snap.uploadFailed.invalidQRCode');
      case SnapErrorType.WORKSHEET_NOT_FOUND:
        return t('snap.uploadFailed.worksheetNotFound');
      case SnapErrorType.UNEXPECTED_ERROR:
        return t('snap.uploadFailed.unexpectedError');
      default:
        return t('snap.uploadFailed.unknown');
    }
  };

  if (snap.state === SnapState.COMPLETED) {
    if (snap.data && snap.data.feedback) {
      return <SnapResultsMessages snap={snap} messages={snap.data.feedback} />;
    } else if (snap.data && snap.data.questions) {
      return <SnapResultsTable snap={snap} />;
    } else {
      sentry_sdk.setContext('snap', snap);
      sentry_sdk.captureMessage('Snap data is missing');
      return <p>{t('snap.resultsTable.noData.errorMessage')}</p>;
    }
  }

  return (
    <>
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="w-full max-w-prose">
          {snap.state === SnapState.ERROR && (
            <Alert variant="destructive" className="mb-5">
              <AlertCircle className="h-4 w-4" />
              <AlertTitle>{t('snap.uploadFailed.title')}</AlertTitle>
              <AlertDescription>
                {getErrorMessage(snap.error_type)}
              </AlertDescription>
            </Alert>
          )}
          {isProcessing(snap.state) ? (
            <div className="flex items-center justify-center w-full h-full">
              <LoaderIcon className="w-12 h-12 animate-spin" />
            </div>
          ) : (
            <label className="flex items-center w-full h-auto justify-center p-16 rounded-full bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 transition-transform transform hover:scale-105 active:scale-95 animate-pulse">
              <Camera className="w-full h-auto" />
              <input
                required
                capture="environment"
                type="file"
                name="photo"
                accept="image/*"
                onChange={handleUpload}
                className="hidden"
              />
            </label>
          )}
        </div>
      </div>
    </>
  );
};

export default SnapUpload;
