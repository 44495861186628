import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WorksheetPartPatchSchema } from '../../../api';
import getApiService from '../../../services/api';
import WorksheetPartWrapper, {
  WorksheetPartWrapperProps,
} from './WorksheetPartWrapper';

interface AnswerProps {
  text: string;
  points: number;
}

interface DataProps {
  answers: AnswerProps[];
}

const WorksheetMCQuestionPart: React.FC<WorksheetPartWrapperProps> = ({
  ...props
}) => {
  // State for question, answers, and correct answer
  const { t } = useTranslation();
  const [part] = useState(props.part);
  const [text, setText] = useState(part.text ?? '');
  const [data, setData] = useState<DataProps>((part.data || {}) as DataProps);

  data.answers = data.answers || [
    { text: '', points: 0 },
    { text: '', points: 0 },
    { text: '', points: 0 },
    { text: '', points: 0 },
  ];

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus(); // Focus the input
    }
  }, []);

  const debouncedOnChange = useRef(
    _.debounce((patch: WorksheetPartPatchSchema) => {
      const updatePart = async () => {
        const api = await getApiService();
        if (!api) return;
        const updatedWorksheet = await api.appApiWorksheetsUpdateWorksheetPart(
          props.worksheetId,
          part.id,
          patch
        );
        props.onChange(updatedWorksheet);
      };
      updatePart();
    }, 500)
  ).current;

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newText = e.target.value;
    setText(newText);
    debouncedOnChange({ text: newText });
  };

  // Handle answer change
  const handleAnswerChange = (index: number, value: string) => {
    const newData = { ...data };
    newData.answers[index].text = value;
    setData(newData);
    debouncedOnChange({ data: newData });
  };

  // Handle points change
  const handlePointsChange = (index: number, value: number) => {
    const newData = { ...data };
    newData.answers[index].points = value;
    setData(newData);
    debouncedOnChange({ data: newData });
  };

  return (
    <WorksheetPartWrapper {...props}>
      {/* Question Input */}
      <div className="mb-4">
        <input
          ref={inputRef}
          type="text"
          value={text}
          onChange={handleTextChange}
          className="border border-gray-400 rounded-lg p-2 w-full"
          placeholder={t('worksheet.edit.questionPlaceholder')}
        />
      </div>

      {/* Answers Inputs */}
      {data.answers.map((answer, index) => (
        <div key={index} className="mb-2 flex items-center">
          <input
            type="text"
            value={answer.text}
            onChange={(e) => {
              handleAnswerChange(index, e.target.value);
            }}
            className="border border-gray-400 rounded-lg p-2 flex-grow mr-2"
            placeholder={t('worksheet.edit.answerPlaceholder', {
              i: index + 1,
            })}
          />
          <div className="flex items-center">
            <input
              type="number"
              value={answer.points}
              onChange={(e) => {
                handlePointsChange(index, Number(e.target.value));
              }}
              className="border border-gray-400 rounded-lg p-2 w-20"
              placeholder={t('worksheet.edit.pointsPlaceholder')}
            />
            <span className="ml-1">
              {t('worksheet.edit.pointsAbbreviation')}
            </span>
          </div>
        </div>
      ))}
    </WorksheetPartWrapper>
  );
};

export default WorksheetMCQuestionPart;
