/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum SnapState {
    CREATED = 'created',
    UPLOADED = 'uploaded',
    VALIDATING = 'validating',
    PROCESSING = 'processing',
    COMPLETED = 'completed',
    ERROR = 'error',
}
