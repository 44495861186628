import { useAuth } from '@workos-inc/authkit-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import useAuthStore from '../../stores/authStore';
import { Button, ButtonProps } from '../ui/button';

export interface SignInButtonProps extends ButtonProps {
  text?: string;
}

const SignInButton = React.forwardRef<HTMLButtonElement, SignInButtonProps>(
  ({ text, ...props }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const user = useAuthStore((state) => state.user);
    const { signIn, isLoading } = useAuth();

    console.log('User:', user);

    if (user) {
      return (
        <Button asChild {...props}>
          <Link to="/app">{t('nav.goToApp')}</Link>
        </Button>
      );
    }

    const saveUrl = () => {
      localStorage.setItem('redirectUrl', location.pathname);
    };

    return (
      <Button
        onClick={() => {
          saveUrl();
          signIn();
        }}
        disabled={isLoading}
        {...props}
      >
        {text || t('nav.sign_in')}
      </Button>
    );
  }
);

SignInButton.displayName = 'SignInButton';

export default SignInButton;
