import { Ellipsis } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SnapSchema } from '../../../api';
import getApiService from '../../../services/api';
import { Avatar, AvatarFallback, AvatarImage } from '../../ui/avatar';
import { Card, CardContent } from '../../ui/card';

interface SnapResultsMessagesProps {
  snap: SnapSchema;
  messages: string[];
}

const SnapResultsMessages: React.FC<SnapResultsMessagesProps> = ({
  snap,
  messages,
}) => {
  const { t } = useTranslation();
  const [isTyping, setIsTyping] = useState<boolean>(!snap.viewed_results); // Starts typing if results are not viewed
  // const [isTyping, setIsTyping] = useState<boolean>(true); // Starts typing if results are not viewed
  const [messageIndex, setMessageIndex] = useState<number>(0);
  const [renderedMessages, setRenderedMessages] = useState<string[]>([]);

  const setSnapViewed = async () => {
    const api = await getApiService();
    if (!api) return;
    await api.appApiSnapsSnapViewed(snap.id);
  };

  useEffect(() => {
    if (!snap.viewed_results) setSnapViewed();
  }, [snap]);

  // Typing effect logic, only if snap.viewed_results is false
  useEffect(() => {
    if (!isTyping) {
      // If snap has been viewed, display all messages at once
      setRenderedMessages(messages);
    } else if (messageIndex < messages.length) {
      // Display the entire message at once
      const pauseInterval = setTimeout(() => {
        setRenderedMessages((prev) => [...prev, messages[messageIndex]]);
        setMessageIndex(messageIndex + 1);
      }, 1000); // Delay before displaying the next message

      return () => clearTimeout(pauseInterval);
    } else {
      setIsTyping(false); // Typing is complete
    }
  }, [messageIndex, messages, isTyping]);

  const Message = ({ text }: { text: string }) => {
    return (
      <Card
        className="bg-white p-5 w-full flex items-start space-x-4 transition-transform duration-300" // Reduced padding
      >
        <Avatar>
          <AvatarImage src="/avatar.png" alt="@shadcn" />
          <AvatarFallback>ChatBot</AvatarFallback>
        </Avatar>
        <CardContent className="p-0 w-full">
          <span className="text-gray-800">{text}</span>
        </CardContent>
      </Card>
    );
  };

  return (
    <div className="space-y-4 p-4 max-w-screen-sm mx-auto">
      <h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl w-full">
        {t('snapResults.resultsAreReady.title')}
      </h1>
      {renderedMessages.map((msg, index) => (
        <Message key={index} text={msg} />
      ))}
      {isTyping && <Ellipsis />} {/* Optional loading indicator */}
    </div>
  );
};

export default SnapResultsMessages;
