// src/components/features/worksheets/WorksheetExtraFileUploader.tsx
import * as Sentry from '@sentry/react';
import { Download, Loader, X } from 'lucide-react';
import { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import { useDebouncedCallback } from 'use-debounce';
import { ExtraFileSchema } from '../../../api';
import getApiService from '../../../services/api';
import { Button } from '../../ui/button';
import { Input } from '../../ui/input';

interface WorksheetExtraFileUploaderProps {
  worksheetId: string;
  file: ExtraFileSchema | null | undefined;
}

const WorksheetExtraFileUploader: React.FC<WorksheetExtraFileUploaderProps> = ({
  worksheetId,
  file,
}) => {
  const [localFile, setLocalFile] = useState<
    ExtraFileSchema | null | undefined
  >(file);
  const [name, setName] = useState(file?.name);
  const { t } = useTranslation();
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const allowedFileTypes = [
    'PDF',
    'DOCX',
    'DOC',
    'ODT',
    'XLSX',
    'XLS',
    'PPTX',
    'PPT',
  ];

  const handleFileUpload = async (file: File) => {
    const api = await getApiService();

    if (!api || !file) return;

    if (localFile) {
      toast.warning(t('worksheet.file.alreadyUploaded'));
      return;
    }

    setIsUploading(true);

    try {
      const updatedFile = await api.appApiWorksheetsUploadWorksheetExtraFile(
        worksheetId,
        { file: file }
      );
      setLocalFile(updatedFile);
      setName(updatedFile.name);
    } catch (error) {
      Sentry.captureException(error);
      toast.error(t('error.uploadExtraFile'));
    } finally {
      setIsUploading(false);
    }
  };

  const debouncedFileRename = useDebouncedCallback((name: string) => {
    handleFileRename(name);
  }, 500);

  const handleFileRename = async (name: string) => {
    const api = await getApiService();

    if (!api || !localFile) return;

    try {
      const updatedFile = await api.appApiWorksheetsRenameExtraFile(
        worksheetId,
        name
      );
      setName(updatedFile.name);
      toast.success(t('worksheet.file.renameSuccess'));
    } catch (error) {
      Sentry.captureException(error);
      toast.error(t('error.renameExtraFile'));
    }
  };

  console.log(handleFileRename);

  const handleFileDelete = async () => {
    const api = await getApiService();

    if (!api || !localFile) return;

    try {
      await api.appApiWorksheetsDeleteWorksheetExtraFile(worksheetId);
      setLocalFile(null);
    } catch (error) {
      Sentry.captureException(error);
      toast.error(t('error.deleteExtraFile'));
    }
  };

  const handleSizeError = () => {
    setError(t('worksheet.file.sizeError'));
  };

  return (
    <>
      <label className="mb-2 text-sm">{t('worksheet.file.label')}</label>
      {localFile ? (
        <div className="flex flex-col space-y-4">
          <Input
            value={name}
            onChange={(e) => {
              debouncedFileRename(e.target.value);
              setName(e.target.value);
            }}
            placeholder={t('worksheet.file.placeholderName')}
            className="w-full"
          />

          <div className="flex items-center justify-between space-x-2">
            <Button
              asChild
              variant="outline"
              className="flex items-center space-x-2"
            >
              <a href={localFile.file} target="_blank" rel="noreferrer">
                <Download className="w-5 h-5" />
                <span>{t('worksheet.file.viewLink')}</span>
              </a>
            </Button>

            <Button
              onClick={handleFileDelete}
              variant="ghost"
              className="p-2 text-red-400 hover:bg-red-50"
            >
              <X className="w-5 h-5" />
            </Button>
          </div>
        </div>
      ) : (
        <FileUploader
          handleChange={handleFileUpload}
          name="file"
          types={allowedFileTypes}
          maxSize={2}
          onSizeError={handleSizeError}
        >
          <div
            className={`h-32 flex flex-col items-center justify-center border-2 border-dashed ${
              error ? 'border-red-300' : 'border-gray-300'
            } rounded-lg cursor-pointer hover:border-blue-400`}
          >
            <p
              className={`text-sm ${error ? 'text-red-400' : 'text-gray-500'}`}
            >
              {isUploading ? (
                <Loader className="w-6 h-6 animate-spin" />
              ) : (
                <>
                  {error ? (
                    <span className="font-bold">{error}</span>
                  ) : (
                    t('worksheet.file.uploadPrompt')
                  )}
                  <br />
                  <span className="text-xs text-gray-400">
                    {t('worksheet.file.dragOrSelect')}
                  </span>
                </>
              )}
            </p>
          </div>
        </FileUploader>
      )}
    </>
  );
};

export default WorksheetExtraFileUploader;
