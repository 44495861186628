import React from 'react';
import { Outlet } from 'react-router-dom';
import { SidebarInset, SidebarProvider, SidebarTrigger } from '../ui/sidebar';
import { Toaster } from '../ui/sonner';
import { AppSidebar } from './AppSidebar';

const AppLayout: React.FC = () => {
  return (
    <SidebarProvider>
      <AppSidebar />
      {/* <div className="flex flex-col h-screen"> */}
      {/* <div className="flex flex-1 overflow-hidden"> */}
      {/* <main className="flex-1 bg-gray-50 overflow-auto lg:pl-24 p-4"> */}
      <SidebarInset>
        <header className="flex h-16 shrink-0 items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12">
          <div className="flex items-center gap-2 px-4">
            <SidebarTrigger className="-ml-1" />
            {/* <Separator orientation="vertical" className="mr-2 h-4" />
            <Breadcrumb>
              <BreadcrumbList>
                <BreadcrumbItem className="hidden md:block">
                  <BreadcrumbLink href="#">
                    Building Your Application
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator className="hidden md:block" />
                <BreadcrumbItem>
                  <BreadcrumbPage>Data Fetching</BreadcrumbPage>
                </BreadcrumbItem>
              </BreadcrumbList>
            </Breadcrumb> */}
          </div>
        </header>
        <div className="flex flex-1 flex-col gap-4 p-4 pt-0">
          <Outlet />
        </div>
      </SidebarInset>
      {/* </main> */}
      {/* </div> */}
      {/* </div> */}
      <Toaster />
    </SidebarProvider>
  );
};

export default AppLayout;
