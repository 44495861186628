// src/pages/AssessmentDetail.tsx
import { Popover } from '@radix-ui/react-popover';
import * as Sentry from '@sentry/react';
import {
  AlertCircle,
  BarChart2,
  CheckCircle,
  Clock,
  Download,
  ExternalLink,
  HelpCircle,
  ImageIcon,
  Loader,
  Upload,
  XCircle,
} from 'lucide-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import {
  AssessmentDetailSchema,
  FeedbackType,
  SnapErrorType,
  SnapState,
} from '../api';
import Link from '../components/common/Link';
import SnapResultsTable from '../components/features/snaps/SnapResultsTable';
import { Button } from '../components/ui/button';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '../components/ui/card';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../components/ui/dialog';
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '../components/ui/drawer';
import { PopoverContent, PopoverTrigger } from '../components/ui/popover';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from '../components/ui/table';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../components/ui/tooltip';
import getApiService from '../services/api';

export const assessmentDetailLoader = async ({
  params,
}: LoaderFunctionArgs) => {
  const api = await getApiService();
  if (!api) return;
  const { id } = params;
  if (!id) {
    throw new Error('Assessment ID is missing.');
  }

  const assessment = await api.appApiAssessmentsGetAssessmentDetail(id);
  console.log('Fetched assessment:', assessment);
  return assessment;
};

const AssessmentDetail = () => {
  const { t } = useTranslation();
  const [assessment, setAssessment] = useState<AssessmentDetailSchema>(
    useLoaderData() as AssessmentDetailSchema
  );
  const [isGenerating, setIsGenerating] = useState(
    assessment.pdf ? false : true
  );

  const { name, copies, snaps, created_at, submissions, avg_score } =
    assessment;

  const refreshAssessment = async () => {
    if (!assessment.id) {
      return;
    }
    const api = await getApiService();
    if (!api) return;

    try {
      const updatedAssessment = await api.appApiAssessmentsGetAssessmentDetail(
        assessment.id
      );
      setAssessment(updatedAssessment);
      console.log('Refreshed assessment:', updatedAssessment);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const getErrorMessage = (error_type: SnapErrorType | null | undefined) => {
    switch (error_type) {
      case SnapErrorType.MISSING_MARKERS:
        return t('snap.uploadFailed.missingMarkers');
      case SnapErrorType.INVALID_QR_CODE:
        return t('snap.uploadFailed.invalidQRCode');
      case SnapErrorType.WORKSHEET_NOT_FOUND:
        return t('snap.uploadFailed.worksheetNotFound');
      case SnapErrorType.UNEXPECTED_ERROR:
        return t('snap.uploadFailed.unexpectedError');
      default:
        return t('snap.uploadFailed.unknown');
    }
  };

  useEffect(() => {
    if (!assessment.pdf) {
      const interval = setInterval(() => {
        refreshAssessment();
      }, 1000);

      return () => clearInterval(interval);
    } else {
      setIsGenerating(false);
    }
  }, [assessment.pdf, refreshAssessment]);

  return (
    <div className="max-w-screen-xl">
      <h1 className="text-3xl font-bold mb-6">
        {t('assessmentDetail.title', { name })}
      </h1>

      <Card className="mb-8">
        <CardHeader>
          <CardTitle>{t('assessmentDetail.overview.title')}</CardTitle>
        </CardHeader>
        <CardContent className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          <div className="flex items-center space-x-2">
            <span className="font-medium">
              {t('assessmentDetail.overview.createdOn')}:
            </span>
            <span>{new Date(created_at).toLocaleDateString()}</span>
          </div>
          <div className="flex items-center space-x-2">
            <span className="font-medium">
              {t('assessmentDetail.overview.copies')}:
            </span>
            <span>{copies}</span>
          </div>
          <div className="flex items-center space-x-2">
            <span className="font-medium">
              {t('assessmentDetail.overview.averageScore')}:
            </span>
            <span>
              {avg_score
                ? `${avg_score.toFixed(2)}`
                : t('assessmentDetail.overview.noResultsYet')}
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <span className="font-medium">
              {t('assessmentDetail.overview.submissions')}:
            </span>
            <span>{submissions}</span>
          </div>
          <div className="flex items-center space-x-2">
            <span className="font-medium">
              {t('assessmentDetail.overview.feedbackType')}:
            </span>
            <span>
              <Popover>
                <PopoverTrigger className="flex items-center">
                  {assessment.feedback_type == FeedbackType.FORMATIVE
                    ? t('feedbackType.formative.label')
                    : t('feedbackType.summative.label')}
                  <HelpCircle className="text-gray-500 h-5 w-5 ml-2" />
                </PopoverTrigger>
                <PopoverContent>
                  {assessment.feedback_type == FeedbackType.FORMATIVE
                    ? t('feedbackType.formative.help')
                    : t('feedbackType.summative.help')}
                </PopoverContent>
              </Popover>
            </span>
          </div>
        </CardContent>
      </Card>

      {isGenerating ? (
        <div className="border border-dashed border-gray-300 bg-blue-50 rounded-lg p-4 mb-4 flex items-center space-x-2">
          <Loader className="h-5 w-5 text-gray-600 animate-spin" />
          <span className="text-gray-700 font-medium">
            {t('assessmentDetail.status.generating')}
          </span>
        </div>
      ) : (
        <div className="flex space-x-4 mb-8">
          {assessment.pdf && (
            <Button
              asChild
              variant="default"
              className="flex items-center space-x-2"
            >
              <a
                href={assessment.pdf}
                download="assessment_report.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <Download className="h-4 w-4" />
                <span>{t('assessmentDetail.actions.downloadPDF')}</span>
              </a>
            </Button>
          )}

          {assessment.extra_file && (
            <Button
              asChild
              variant="secondary"
              className="flex items-center space-x-2"
            >
              <a
                href={assessment.extra_file.file}
                download={assessment.extra_file.name}
                target="_blank"
                rel="noreferrer"
              >
                <Download className="h-4 w-4" />
                <span>
                  {t('assessmentDetail.actions.downloadExtraFile', {
                    name: assessment.extra_file.name,
                  })}
                </span>
              </a>
            </Button>
          )}
        </div>
      )}

      <Card>
        <CardHeader>
          <CardTitle>{t('assessmentDetail.snaps.title')}</CardTitle>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell>
                  {t('assessmentDetail.snaps.table.number')}
                </TableCell>
                <TableCell>
                  {t('assessmentDetail.snaps.table.status')}
                </TableCell>
                <TableCell>
                  {t('assessmentDetail.snaps.table.points')}
                </TableCell>
                <TableCell>
                  {t('assessmentDetail.snaps.table.actions')}
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {snaps.map((snap) => (
                <TableRow key={snap.id}>
                  <TableCell>{snap.number}</TableCell>
                  <TableCell>
                    {(() => {
                      switch (snap.state) {
                        case SnapState.CREATED:
                          return (
                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger>
                                  <Clock className="text-gray-500 h-5 w-5" />
                                </TooltipTrigger>
                                <TooltipContent>
                                  {t('assessmentDetail.snapState.created')}
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          );
                        case SnapState.UPLOADED:
                          return (
                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger>
                                  <Loader className="text-yellow-500 h-5 w-5 animate-spin" />
                                </TooltipTrigger>
                                <TooltipContent>
                                  {t('assessmentDetail.snapState.uploaded')}
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          );
                        case SnapState.PROCESSING:
                          return (
                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger>
                                  <Loader className="text-blue-500 h-5 w-5 animate-spin" />
                                </TooltipTrigger>
                                <TooltipContent>
                                  {t('assessmentDetail.snapState.processing')}
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          );
                        case SnapState.COMPLETED:
                          return (
                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger>
                                  <CheckCircle className="text-green-500 h-5 w-5" />
                                </TooltipTrigger>
                                <TooltipContent>
                                  {t('assessmentDetail.snapState.completed')}
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          );
                        case SnapState.ERROR:
                          return (
                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger>
                                  <XCircle className="text-red-500 h-5 w-5" />
                                </TooltipTrigger>
                                <TooltipContent>
                                  {getErrorMessage(snap.error_type)}
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          );
                        default:
                          return (
                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger>
                                  <AlertCircle className="text-gray-500 h-5 w-5" />
                                </TooltipTrigger>
                                <TooltipContent>
                                  {t('assessmentDetail.snapState.unknown')}
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          );
                      }
                    })()}
                  </TableCell>
                  <TableCell>
                    {snap.state == SnapState.COMPLETED ? (
                      snap.score
                    ) : (
                      <i>{t('assessmentDetail.snaps.notCompleted')}</i>
                    )}
                  </TableCell>
                  <TableCell>
                    <div className="flex space-x-2">
                      {/* View Upload Button */}
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger>
                            <Button
                              asChild
                              variant="ghost"
                              disabled={snap.state != SnapState.CREATED}
                            >
                              <Link
                                to={`/snap/upload/${snap.id}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {snap.state == SnapState.CREATED ? (
                                  <Upload className="w-6 h-6" />
                                ) : (
                                  <ExternalLink className="w-6 h-6" />
                                )}
                              </Link>
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            {snap.state == SnapState.CREATED
                              ? t('assessmentDetail.snap.actions.uploadSnap')
                              : t('assessmentDetail.snap.actions.viewSnap')}
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>

                      {/* View Uploaded Image Button */}
                      <Dialog>
                        <DialogTrigger>
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger>
                                <Button
                                  variant="ghost"
                                  disabled={snap.processed_image ? false : true}
                                >
                                  <ImageIcon className="w-6 h-6" />{' '}
                                </Button>
                              </TooltipTrigger>
                              <TooltipContent>
                                {t('assessmentDetail.snap.actions.viewImage')}
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </DialogTrigger>
                        <DialogContent>
                          <DialogHeader>
                            <DialogTitle>
                              {t('assessmentDetail.snap.uploadedImage.title')}
                            </DialogTitle>
                            <DialogDescription>
                              {snap.processed_image ? (
                                <img
                                  src={snap.processed_image}
                                  alt={t(
                                    'assessmentDetail.snap.uploadedImage.alt'
                                  )}
                                  className="w-full"
                                  loading="lazy"
                                />
                              ) : (
                                <p>
                                  {t(
                                    'assessmentDetail.snap.uploadedImage.noImage'
                                  )}
                                </p>
                              )}
                            </DialogDescription>
                          </DialogHeader>
                        </DialogContent>
                      </Dialog>

                      {/* View Detailed Results Button */}
                      <Drawer>
                        <DrawerTrigger>
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger>
                                <Button
                                  onClick={() =>
                                    console.log('View detailed results')
                                  }
                                  variant="ghost"
                                  disabled={snap.data ? false : true}
                                >
                                  <BarChart2 className="w-6 h-6" />{' '}
                                </Button>
                              </TooltipTrigger>
                              <TooltipContent>
                                {t('assessmentDetail.snap.actions.viewResults')}
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </DrawerTrigger>
                        <DrawerContent>
                          <DrawerHeader>
                            <DrawerTitle>
                              {t('assessmentDetail.snap.results.title')}
                            </DrawerTitle>
                            <DrawerDescription>
                              <SnapResultsTable snap={snap} />
                            </DrawerDescription>
                          </DrawerHeader>
                        </DrawerContent>
                      </Drawer>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
};

export default AssessmentDetail;
