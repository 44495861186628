import * as Sentry from '@sentry/react';
import { Loader, X } from 'lucide-react';
import { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import getApiService from '../../../services/api';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../ui/select';
import WorksheetPartWrapper, {
  WorksheetPartWrapperProps,
} from './WorksheetPartWrapper';

const WorksheetImagePart: React.FC<WorksheetPartWrapperProps> = ({
  onRemove,
  ...props
}) => {
  const { t } = useTranslation();
  const [part] = useState(props.part);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [uploadedImage, setUploadedImage] = useState<string | null>(
    part.image || null
  );
  const [imageSize, setImageSize] = useState<'small' | 'medium' | 'large'>(
    part.data?.imageSize ?? 'medium'
  );

  const imageFileTypes = ['JPG', 'PNG'];

  const handleImageRemove = async () => {
    const api = await getApiService();
    if (!api) return;
    if (!uploadedImage) return;

    try {
      const updatedWorksheet =
        await api.appApiWorksheetsDeleteWorksheetPartImage(
          props.worksheetId,
          part.id
        );
      setUploadedImage(null);
      props.onChange(updatedWorksheet);
    } catch (error) {
      Sentry.captureException(error);
      toast.error(t('worksheet.imagePart.error.delete'));
    }
  };

  const handlePartRemove = async () => {
    await handleImageRemove();
    onRemove();
  };

  const handleImageSizeChange = async (size: 'small' | 'medium' | 'large') => {
    const api = await getApiService();
    if (!api) return;

    const updatedWorksheet = await api.appApiWorksheetsUpdateWorksheetPart(
      props.worksheetId,
      part.id,
      {
        data: {
          imageSize: size,
        },
      }
    );
    setImageSize(size);
    props.onChange(updatedWorksheet);
  };

  const handleFileChange = async (file: File) => {
    const api = await getApiService();

    if (!api) return;
    if (!file) return;

    setUploadedImage(URL.createObjectURL(file));

    if (uploadedImage) {
      toast.warning(t('worksheet.imagePart.warning.alreadyUploaded'));
      return;
    }

    setIsUploading(true);

    try {
      const updatedWorksheet =
        await api.appApiWorksheetsUploadWorksheetPartImage(
          props.worksheetId,
          part.id,
          {
            image: file,
          }
        );
      console.log('Uploaded image:', updatedWorksheet);
      props.onChange(updatedWorksheet);
    } catch (error) {
      Sentry.captureException(error);
      toast.error(t('worksheet.imagePart.error.upload'));
    } finally {
      setIsUploading(false);
    }
  };

  const handleSizeError = () => {
    setError(t('worksheet.imagePart.error.size'));
  };

  return (
    <WorksheetPartWrapper onRemove={handlePartRemove} {...props}>
      {uploadedImage ? (
        <>
          <div className="flex items-center justify-center">
            <div className="relative">
              <img
                src={uploadedImage}
                alt={t('worksheet.imagePart.altText')}
                className="object-contain min-h-64 rounded-lg shadow-md border border-gray-300"
              />
              <button
                onClick={handleImageRemove}
                className="absolute top-2 right-2 bg-white rounded-full p-1 shadow-md hover:bg-gray-100 border border-red-200"
              >
                <X className="w-5 h-5 text-red-400" />
              </button>
            </div>
          </div>

          <div className="mt-4">
            <Select
              onValueChange={handleImageSizeChange}
              defaultValue={imageSize}
            >
              <SelectTrigger className="w-[180px]">
                <SelectValue
                  placeholder={t('worksheet.image.size.placeholder')}
                />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="small">
                  {t('worksheet.image.size.small')}
                </SelectItem>
                <SelectItem value="medium">
                  {t('worksheet.image.size.medium')}
                </SelectItem>
                <SelectItem value="large">
                  {t('worksheet.image.size.large')}
                </SelectItem>
              </SelectContent>
            </Select>
          </div>
        </>
      ) : (
        <FileUploader
          handleChange={handleFileChange}
          name="file"
          types={imageFileTypes}
          maxSize={2}
          onSizeError={handleSizeError}
        >
          <div
            className={`h-64 flex flex-col items-center justify-center border-2 border-dashed ${
              error ? 'border-red-300' : 'border-gray-300'
            } rounded-lg cursor-pointer hover:border-blue-400`}
          >
            <p
              className={`text-sm ${error ? 'text-red-400' : 'text-gray-500'}`}
            >
              {isUploading ? (
                <Loader className="w-6 h-6 animate-spin" />
              ) : (
                <>
                  {error ? (
                    <span className="font-bold">{error}</span>
                  ) : (
                    t('worksheet.imagePart.prompt.upload')
                  )}
                  <br />
                  <span className="text-xs text-gray-400">
                    {t('worksheet.imagePart.prompt.dragOrSelect')}
                  </span>
                </>
              )}
            </p>
          </div>
        </FileUploader>
      )}
    </WorksheetPartWrapper>
  );
};

export default WorksheetImagePart;
