import { InfoIcon } from 'lucide-react'; // Import an icon from lucide-react
import { useTranslation } from 'react-i18next';
import Link from '../common/Link';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="py-8 bg-gray-800 text-white">
      <div className="container mx-auto flex items-center">
        <p className="mr-4 text-gray-300">
          {t('footer.copy', { year: new Date().getFullYear() })}
        </p>
        {/* Icon between copyright and nav links */}
        <InfoIcon className="mx-2" size={16} />{' '}
        {/* You can adjust the size as needed */}
        <nav className="flex space-x-2">
          <Link to="/page/privacy-policy" className="hover:text-teal-400">
            {t('nav.privacy')}
          </Link>
          <span className="text-white">|</span> {/* Separator */}
          <Link to="/page/terms-of-service" className="hover:text-teal-400">
            {t('nav.terms')}
          </Link>
          <span className="text-white">|</span> {/* Separator */}
          <Link to="/contact" className="hover:text-teal-400">
            {t('nav.contact')}
          </Link>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
