import {
  ImageIcon,
  PlusCircle,
  SquareCheck,
  SquarePen,
  TextIcon,
} from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WorksheetPartSchema } from '../../../api';
import { Button } from '../../ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '../../ui/popover';

interface WorksheetAddPartPopoverProps {
  onAddPart: (partType: WorksheetPartSchema.type) => void; // Specify the function type
}

const WorksheetAddPartPopover: React.FC<WorksheetAddPartPopoverProps> = ({
  onAddPart,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger>
        <PlusCircle size={24} />
      </PopoverTrigger>
      <PopoverContent>
        <Button
          className="w-full my-1"
          onClick={() => {
            setIsOpen(false);
            onAddPart(WorksheetPartSchema.type.TEXT);
          }}
        >
          <TextIcon className="mr-2" /> {t('action.addText')}
        </Button>
        <Button
          className="w-full my-2"
          onClick={() => {
            setIsOpen(false);
            onAddPart(WorksheetPartSchema.type.IMAGE);
          }}
        >
          <ImageIcon className="mr-2" /> {t('action.addImage')}
        </Button>
        <Button
          className="w-full my-2"
          onClick={() => {
            setIsOpen(false);
            onAddPart(WorksheetPartSchema.type.MC);
          }}
        >
          <SquareCheck className="mr-2" /> {t('action.addMCQuestion')}
        </Button>
        <Button
          className="w-full my-2"
          onClick={() => {
            setIsOpen(false);
            onAddPart(WorksheetPartSchema.type.OPEN);
          }}
        >
          <SquarePen className="mr-2" /> {t('action.addOpenQuestion')}
        </Button>
      </PopoverContent>
    </Popover>
  );
};

export default WorksheetAddPartPopover;
