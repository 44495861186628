import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import getApiService from '../../../services/api';
import { AutosizeTextarea } from '../../ui/autosize-textarea';
import WorksheetPartWrapper, {
  WorksheetPartWrapperProps,
} from './WorksheetPartWrapper';

const WorksheetTextPart: React.FC<WorksheetPartWrapperProps> = ({
  ...props
}) => {
  const [text, setText] = useState<string>(props.part.text || '');

  // Memoize the debounced function to avoid re-creating it on every render
  const debouncedOnChange = useRef(
    _.debounce((newText: string) => {
      const updateText = async () => {
        const api = await getApiService();
        if (!api) return;
        const updatedWorksheet = await api.appApiWorksheetsUpdateWorksheetPart(
          props.worksheetId,
          props.part.id,
          {
            text: newText,
          }
        );
        props.onChange(updatedWorksheet);
      };

      updateText();
    }, 500)
  ).current;

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = e.target.value;
    setText(newText); // Update local state immediately
    debouncedOnChange(newText); // Debounced update to parent
  };

  useEffect(() => {
    setText(text || '');
  }, [text]);

  return (
    <WorksheetPartWrapper {...props}>
      <AutosizeTextarea onChange={handleChange} value={text || ''} />
    </WorksheetPartWrapper>
  );
};

export default WorksheetTextPart;
