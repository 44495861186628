// src/components/features/worksheets/WorksheetPrintModal.tsx
import { Loader } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { WorksheetSchema } from '../../../api';
import getApiService from '../../../services/api';
import useAuthStore from '../../../stores/authStore';
import SignInButton from '../../common/SignInButton';
import { Button, ButtonProps } from '../../ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../ui/dialog';
import { Input } from '../../ui/input';

// Props interface for the modal
interface WorksheetPrintModalProps extends ButtonProps {
  worksheet: WorksheetSchema;
  buttonText?: string;
}

// WorksheetPrintModal Component
const WorksheetPrintModal = ({
  worksheet,
  buttonText,
  ...props
}: WorksheetPrintModalProps) => {
  const navigate = useNavigate();
  const user = useAuthStore((state) => state.user);
  const { t } = useTranslation(); // Initialize translation hook
  const [description, setDescription] = useState('');
  const [copies, setCopies] = useState<number>(1);
  const [errors, setErrors] = useState<{
    description?: string;
    copies?: string;
  }>({});
  const [lockCreateButton, setLockCreateButton] = useState(false);

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      setDescription('');
      setCopies(1);
      setErrors({});
    }
  };

  const handleCreateAssessment = async () => {
    setLockCreateButton(true);
    const newErrors: { description?: string; copies?: string } = {};
    const api = await getApiService();
    if (!api) return;

    // Validate inputs
    if (!worksheet || !worksheet.id) {
      return;
    }
    if (!description) {
      newErrors.description = t('error.enterDescription'); // Updated key for error message
    }
    if (!copies || copies <= 0) {
      newErrors.copies = t('error.enterValidCopies'); // Updated key for error message
    }
    if (copies > 100) {
      newErrors.copies = t('error.copiesLimit'); // Updated key for copies limit error
    }

    // Check if there are errors
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLockCreateButton(false);
      return;
    }

    // Clear errors if inputs are valid
    setErrors({});

    const assessment = await api.appApiAssessmentsCreateNewAssesment({
      worksheet_id: worksheet.id,
      name: description,
      copies,
      snap_url: `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? ':' + window.location.port : ''
      }/snap/upload/`,
    });

    if (assessment && assessment.id) {
      navigate(`/app/my/assessments/${assessment.id}`);
    }
  };

  const printButton = user ? (
    <DialogTrigger asChild>
      <Button {...props} disabled={!worksheet.is_valid}>
        {worksheet.is_valid === null && (
          <Loader className="h-4 w-4 mr-2 animate-spin" />
        )}
        {
          buttonText ||
            t('button.printWorksheet') /* Updated key for print button */
        }
      </Button>
    </DialogTrigger>
  ) : (
    <SignInButton text={t('button.signInToPrint')} /> // Updated key for sign-in prompt
  );

  return (
    <Dialog onOpenChange={handleOpenChange}>
      {printButton}
      <DialogContent className="sm:max-w-[425px] p-6">
        <DialogHeader>
          <DialogTitle className="text-xl font-bold mb-4">
            {t('modal.title.printWorksheet')}:{' '}
            {
              worksheet.name ||
                t(
                  'label.unnamedWorksheet'
                ) /* Updated key for unnamed worksheet label */
            }
          </DialogTitle>
        </DialogHeader>

        {/* Description Input */}
        <div className="mb-6">
          <label
            className="block text-sm font-medium mb-2"
            htmlFor="description"
          >
            {
              t(
                'modal.label.enterDescription'
              ) /* Updated key for description label */
            }
          </label>
          <Input
            id="description"
            placeholder={t('placeholder.assessmentDescription')} // Updated key for description placeholder
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className={`mb-2 ${
              errors.description ? 'border-red-500' : 'border-gray-300'
            }`}
          />
          {errors.description && (
            <p className="text-red-500 text-sm">{errors.description}</p>
          )}
          <p className="text-xs text-gray-500">
            {
              t(
                'modal.helper.descriptionClarification'
              ) /* Updated key for description clarification */
            }
          </p>
        </div>

        {/* Copies Input */}
        <div className="mb-6">
          <label className="block text-sm font-medium mb-2" htmlFor="copies">
            {t('modal.label.numberOfCopies') /* Updated key for copies label */}
          </label>
          <Input
            type="number"
            id="copies"
            value={copies}
            min={1}
            onChange={(e) => setCopies(parseInt(e.target.value, 10))}
            className={`w-24 ${
              errors.copies ? 'border-red-500' : 'border-gray-300'
            }`}
          />
          {errors.copies && (
            <p className="text-red-500 text-sm">{errors.copies}</p>
          )}
          <p className="text-xs text-gray-500">
            {
              t(
                'modal.helper.copiesClarification'
              ) /* Updated key for copies clarification */
            }
          </p>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-end">
          <Button
            variant="default"
            onClick={handleCreateAssessment}
            disabled={lockCreateButton}
          >
            {
              t(
                'button.createAssessment'
              ) /* Updated key for create assessment button */
            }
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default WorksheetPrintModal;
