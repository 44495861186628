// src/components/ScrollLink.js
import {
  Link as DefaultLink,
  LinkProps as DefaultLinkProps,
} from 'react-router-dom';

interface LinkProps extends DefaultLinkProps {
  to: string;
  children: React.ReactNode;
}

const Link: React.FC<LinkProps> = ({ to, children, ...props }) => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <DefaultLink to={to} onClick={handleClick} {...props}>
      {children}
    </DefaultLink>
  );
};

export default Link;
