import { Helmet } from 'react-helmet';
import ReactMarkdown from 'react-markdown';
import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import { PageSchema } from '../api';
import getApiService from '../services/api';

const pageLoader = async ({ params }: LoaderFunctionArgs) => {
  const { slug } = params;
  const api = await getApiService();

  if (!slug) {
    throw new Error('Page slug is missing.');
  }

  const page = await api.appApiPagesGetPage(slug);
  return page;
};

const Page = () => {
  const page = useLoaderData() as PageSchema;

  return (
    <div className="flex items-center justify-center min-h-screen">
      <Helmet>
        <title>{page.title}</title>
        <meta name="description" content={page.title} />
      </Helmet>
      <div className="prose p-10">
        <h1>{page.title}</h1>
        <ReactMarkdown>{page.content}</ReactMarkdown>
      </div>
    </div>
  );
};

export default Page;
export { pageLoader };
