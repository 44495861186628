import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import { WorksheetSchema } from '../../../api';
import getApiService from '../../../services/api';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '../../ui/alert-dialog';
import { Button, ButtonProps } from '../../ui/button';

interface WorksheetDeleteButtonProps extends ButtonProps {
  worksheet: WorksheetSchema;
  onDelete?: (worksheetId: string) => void;
}

const WorksheetDeleteButton = ({
  worksheet,
  onDelete,
  ...props
}: WorksheetDeleteButtonProps) => {
  const { t } = useTranslation();
  const handleDeleteWorksheet = async () => {
    const api = await getApiService();

    if (!api) return;

    try {
      // Call the API to create a new worksheet
      const response = await api.appApiWorksheetsDeleteWorksheet(worksheet.id);

      if (response.deleted) {
        toast.success(t('worksheet.deleteSuccess'));
        onDelete && onDelete(worksheet.id);
      }
    } catch (error) {
      console.error('Error deleting worksheet:', error);
      toast.error(t('worksheet.deleteError'));
      // Handle error (e.g., show a toast notification)
    }
  };

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button variant="destructive" {...props} />
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{t('dialog.confirmDeleteTitle')}</AlertDialogTitle>
          <AlertDialogDescription>
            {t('dialog.confirmDeleteDescription')}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>{t('dialog.cancel')}</AlertDialogCancel>
          <AlertDialogAction onClick={handleDeleteWorksheet}>
            {t('dialog.confirm')}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

WorksheetDeleteButton.displayName = 'WorksheetDeleteButton';

export default WorksheetDeleteButton;
