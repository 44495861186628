// src/pages/AssessmentsList.tsx
import { FileIcon, LoaderIcon } from 'lucide-react';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router-dom';
import { toast } from 'sonner';
import { PagedAssessmentSchema } from '../api';
import Link from '../components/common/Link';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '../components/ui/alert-dialog';
import { Button } from '../components/ui/button';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '../components/ui/card';
import { Input } from '../components/ui/input';
import getApiService from '../services/api';

// Define the loader function
export const assessmentsListLoader = async () => {
  const api = await getApiService();
  const assessments = await api.appApiAssessmentsGetAssessmentsList();
  return assessments;
};

// Component that uses the loader
const AssessmentsList = () => {
  const { t } = useTranslation();
  const [assessments, setAssessments] = useState(
    useLoaderData() as PagedAssessmentSchema
  );

  // State for filtering
  const [filterQuery, setFilterQuery] = useState('');

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterQuery(e.target.value);
  };

  const handleDeleteAssessment = async (assessmentId: string) => {
    const api = await getApiService();
    const status = await api.appApiAssessmentsDeleteAssessment(assessmentId);
    if (status['deleted']) {
      const updatedItems = assessments.items.filter(
        (item) => item.id !== assessmentId
      );
      setAssessments({ ...assessments, items: updatedItems });
      toast.success(t('assessmentList.notifications.deletedSuccess'));
    } else {
      toast.error(t('assessmentList.notifications.deleteFailed'));
    }
  };

  const filteredAssessments = assessments?.items.filter((assessment) =>
    (assessment.name + assessment.worksheet.name)
      .toLowerCase()
      .includes(filterQuery.toLowerCase())
  );

  const truncateText = (text: string, maxLength: number) =>
    text.length > maxLength ? text.slice(0, maxLength) + '...' : text;

  // Function to handle part removal confirmation
  const assessmentRemoveDialog = (assessmentId: string) => {
    // Trigger confirmation dialog and remove part if confirmed
    return (
      <AlertDialog>
        <AlertDialogTrigger asChild>
          <Button variant="destructive">{t('common.button.delete')}</Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              {t('assessmentList.dialog.confirmDelete.title')}
            </AlertDialogTitle>
            <AlertDialogDescription>
              {t('assessmentList.dialog.confirmDelete.description')}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>{t('common.dialog.cancel')}</AlertDialogCancel>
            <AlertDialogAction
              onClick={() => handleDeleteAssessment(assessmentId)}
            >
              {t('common.dialog.confirm')}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    );
  };

  return (
    <div className="max-w-screen-xl">
      <Helmet>
        <title>{t('assessmentList.page.title')}</title>
        <meta
          name="description"
          content={t('assessmentList.page.description')}
        />
      </Helmet>

      {/* Filter Input */}
      <div className="flex justify-between items-center mb-4">
        <Input
          type="text"
          placeholder={t('assessmentList.search.placeholder')}
          value={filterQuery}
          onChange={handleFilterChange}
          className="w-1/3"
        />
      </div>

      {filteredAssessments?.length ? (
        <div className="space-y-4">
          {filteredAssessments.map((assessment) => (
            <Card
              key={assessment.id}
              className="grid gap-4 md:grid-cols-6 h-full p-4"
            >
              {/* Cover Image */}
              <Link
                to={`/app/my/assessments/${assessment.id}`}
                className="col-span-3 md:col-span-1 h-48 md:h-full"
              >
                <div className="w-full h-full flex items-stretch">
                  {assessment.worksheet.cover_image ? (
                    <img
                      src={assessment.worksheet.cover_image}
                      alt={
                        assessment.worksheet.name ||
                        t('assessmentList.labels.unnamedWorksheet')
                      }
                      className="object-cover w-full h-full rounded-lg md:rounded-l-lg md:rounded-r-none"
                    />
                  ) : (
                    <FileIcon className="w-full h-full p-4 lg:p-16 rounded-lg md:rounded-l-lg md:rounded-r-none bg-gray-50" />
                  )}
                </div>
              </Link>

              {/* Card Content */}
              <div className="col-span-3 md:col-span-2 flex flex-col justify-between">
                {/* Title */}
                <CardHeader className="p-2 xl:p-4">
                  <CardTitle>
                    <span className="truncate">
                      <Link
                        to={`/app/my/assessments/${assessment.id}`}
                        className="flex items-center"
                      >
                        {!assessment.is_ready && (
                          <LoaderIcon className="w-6 h-6 mr-2 animate-spin" />
                        )}
                        {truncateText(
                          assessment.name ||
                            t('assessmentList.labels.unnamedAssessment'),
                          60
                        )}
                      </Link>
                    </span>
                  </CardTitle>
                </CardHeader>

                {/* Text */}
                <CardContent className="p-2 xl:p-4">
                  <p>
                    {t('assessmentList.labels.worksheetName')}:{' '}
                    <Link to={`/app/my/worksheets/${assessment.worksheet.id}`}>
                      {truncateText(
                        assessment.worksheet.name ||
                          t('assessmentList.labels.unnamedWorksheet'),
                        30
                      )}
                    </Link>
                    <br />
                    {t('assessmentList.labels.status')}: {assessment.copies}{' '}
                    {t('assessmentList.labels.copies')} / 0{' '}
                    {t('assessmentList.labels.submitted')} / 0{' '}
                    {t('assessmentList.labels.pending')}
                  </p>
                </CardContent>

                {/* Buttons */}
                <CardFooter className="flex space-x-2 p-2 xl:p-4">
                  <Link to={`/app/my/assessments/${assessment.id}`}>
                    <Button>{t('common.button.view')}</Button>
                  </Link>
                  {assessmentRemoveDialog(assessment.id)}
                </CardFooter>
              </div>
            </Card>
          ))}
        </div>
      ) : (
        <div className="flex items-center justify-center h-screen ">
          <div className="text-center py-10 px-6 max-w-md">
            <p className="text-lg font-semibold">
              {t('assessmentList.messages.noAssessments')}
            </p>
            <p className="mt-2 text-gray-600">
              {t('assessmentList.messages.empty')}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default AssessmentsList;
