// src/components/features/worksheets/WorksheetCoverUploader.tsx
import * as Sentry from '@sentry/react';
import { Loader, X } from 'lucide-react';
import { useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useTranslation } from 'react-i18next';
import Resizer from 'react-image-file-resizer';
import { toast } from 'sonner';
import getApiService from '../../../services/api';

interface WorksheetCoverUploaderProps {
  worksheetId: string;
  uploadedImage: string | null;
}

const COVER_SIZE = 800;

const WorksheetCoverUploader: React.FC<WorksheetCoverUploaderProps> = ({
  worksheetId,
  uploadedImage,
}) => {
  const [localUploadedImage, setLocalUploadedImage] = useState<string | null>(
    uploadedImage
  );
  const { t } = useTranslation();
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const coverFileTypes = ['JPG', 'PNG'];

  const handleImageResize = (file: File) => {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        COVER_SIZE, // max width
        COVER_SIZE, // max height
        'JPEG', // format
        90, // quality (0-100)
        0, // rotation
        (uri) => {
          resolve(uri);
        },
        'file' // output type
      );
    });
  };

  const handleCoverChange = async (file: File) => {
    const api = await getApiService();

    if (!api || !file) return;

    if (localUploadedImage) {
      toast.warning(t('worksheet.cover.alreadyUploaded'));
      return;
    }

    setIsUploading(true);

    try {
      const resizedImage = (await handleImageResize(file)) as File;
      const newUploadedImage =
        await api.appApiWorksheetsUploadWorksheetCoverImage(worksheetId, {
          image: resizedImage,
        });
      setLocalUploadedImage(newUploadedImage.url);
    } catch (error) {
      Sentry.captureException(error);
      toast.error(t('error.uploadCoverImage'));
    } finally {
      setIsUploading(false);
    }
  };

  const handleCoverDelete = async () => {
    const api = await getApiService();

    if (!api || !localUploadedImage) return;

    try {
      await api.appApiWorksheetsDeleteWorksheetCoverImage(worksheetId);
      setLocalUploadedImage(null);
    } catch (error) {
      Sentry.captureException(error);
      toast.error(t('error.deleteCoverImage'));
    }
  };

  const handleSizeError = () => {
    setError(t('worksheet.cover.sizeError'));
  };

  useEffect(() => {
    setLocalUploadedImage(uploadedImage);
  }, [uploadedImage]);

  return (
    <>
      <label className="mb-2 text-sm">{t('worksheet.cover.label')}</label>
      {localUploadedImage ? (
        <div className="relative w-full h-auto">
          <img
            src={localUploadedImage}
            alt={t('worksheet.imagePlaceholder')}
            className="object-cover w-full h-atuo rounded-lg shadow-md border border-gray-300"
          />

          {/* Delete Icon */}
          <button
            onClick={handleCoverDelete}
            className="absolute top-2 right-2 bg-white rounded-full p-1 shadow-md hover:bg-gray-100 border border-red-200"
          >
            <X className="w-5 h-5 text-red-400" />
          </button>
        </div>
      ) : (
        <FileUploader
          handleChange={handleCoverChange}
          name="file"
          types={coverFileTypes}
          maxSize={2}
          onSizeError={handleSizeError}
        >
          <div
            className={`h-64 flex flex-col items-center justify-center border-2 border-dashed ${
              error ? 'border-red-300' : 'border-gray-300'
            } rounded-lg cursor-pointer hover:border-blue-400`}
          >
            <p
              className={`text-sm ${error ? 'text-red-400' : 'text-gray-500'}`}
            >
              {isUploading ? (
                <Loader className="w-6 h-6 animate-spin" />
              ) : (
                <>
                  {error ? (
                    <span className="font-bold">{error}</span>
                  ) : (
                    t('worksheet.cover.uploadPrompt')
                  )}
                  <br />
                  <span className="text-xs text-gray-400">
                    {t('worksheet.cover.dragOrSelect')}
                  </span>
                </>
              )}
            </p>
          </div>
        </FileUploader>
      )}
    </>
  );
};

export default WorksheetCoverUploader;
