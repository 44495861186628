import React from 'react';
import { Outlet } from 'react-router-dom';
import CookieBanner from '../common/CookieBanner';
import Footer from './Footer';

const BaseLayout: React.FC = () => {
  return (
    <>
      <div className="flex flex-col min-h-screen">
        <main className="flex-grow">
          {/* <Header /> */}
          <Outlet />
        </main>
        <Footer />
      </div>
      <CookieBanner />
    </>
  );
};

export default BaseLayout;
