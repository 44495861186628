// src/pages/WorksheetsList.tsx
import {
  FileIcon,
  Globe,
  LockIcon,
  SortAscIcon,
  SortDescIcon,
} from 'lucide-react';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Link, useLoaderData } from 'react-router-dom';
import { PagedWorksheetSchema } from '../api';
import WorksheetCreateButton from '../components/features/worksheets/WorksheetCreateButton';
import WorksheetPreviewModal from '../components/features/worksheets/WorksheetPreviewModal';
import WorksheetPrintModal from '../components/features/worksheets/WorksheetPrintModal';
import WorksheetDeleteButton from '../components/features/worksheets/WorsksheetDeleteButton';
import { Button } from '../components/ui/button';
import { Card, CardFooter, CardHeader, CardTitle } from '../components/ui/card';
import { Input } from '../components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../components/ui/select';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../components/ui/tooltip';
import getApiService from '../services/api';

// Define the loader function
export const worksheetsLoader = async () => {
  const api = await getApiService();
  const worksheets = await api.appApiWorksheetsListUsersWorksheets();
  return worksheets;
};

// Component that uses the loader
const WorksheetsList = () => {
  const { t } = useTranslation();
  const [worksheets, setWorksheets] = useState<PagedWorksheetSchema>(
    useLoaderData() as PagedWorksheetSchema
  );

  // States for search, filter, and sort
  const [searchQuery, setSearchQuery] = useState('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [filter, setFilter] = useState('all');

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleSortToggle = () => {
    setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };

  const filteredWorksheets = worksheets?.items
    .filter(
      (worksheet) =>
        (worksheet.name || '')
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) &&
        (filter === 'all' ||
          (filter === 'public' && worksheet.is_public) ||
          (filter === 'private' && !worksheet.is_public))
    )
    .sort((a, b) => {
      if (sortOrder === 'asc') {
        return (
          new Date(a.updated_at || a.created_at).getTime() -
          new Date(b.updated_at || b.created_at).getTime()
        );
      }
      return (
        new Date(b.updated_at || b.created_at).getTime() -
        new Date(a.updated_at || a.created_at).getTime()
      );
    });

  const handleDeleteWorksheet = async (worksheetId: string) => {
    const items = worksheets.items.filter((item) => item.id !== worksheetId);
    setWorksheets({ ...worksheets, items });
  };

  return (
    <div className="max-w-screen-xl">
      <Helmet>
        <title>{t('worksheets.list.title')}</title>
        <meta name="description" content={t('worksheets.list.description')} />
      </Helmet>

      {/* Search, Filter, and Sort Controls */}
      <div className="flex justify-between items-center mb-4">
        <Input
          type="text"
          placeholder={t('label.searchWorksheets')}
          value={searchQuery}
          onChange={handleSearch}
          className="w-1/4"
        />
        <WorksheetCreateButton>
          {t('button.createWorksheet')}
        </WorksheetCreateButton>
        <div className="flex items-center space-x-4">
          <Select value={filter} onValueChange={setFilter}>
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder={t('label.filterByIsPublic')} />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">{t('label.all')}</SelectItem>
              <SelectItem value="public">{t('label.public')}</SelectItem>
              <SelectItem value="private">{t('label.private')}</SelectItem>
            </SelectContent>
          </Select>
          <Button onClick={handleSortToggle} variant="outline">
            {sortOrder === 'asc' ? <SortAscIcon /> : <SortDescIcon />}
            {t('label.sortByDate')}
          </Button>
        </div>
      </div>

      {filteredWorksheets?.length ? (
        <div className="space-y-4">
          {filteredWorksheets.map((worksheet) => (
            <Card
              key={worksheet.id}
              className="grid gap-4 md:grid-cols-6 h-full p-4"
            >
              {/* Cover Image */}
              <div className="col-span-3 md:col-span-1 h-48 md:h-full">
                <Link
                  to={`/app/my/worksheets/edit/${worksheet.id}`}
                  className="w-full h-full flex items-stretch"
                >
                  {worksheet.cover_image ? (
                    <img
                      src={worksheet.cover_image}
                      alt={worksheet.name || t('label.unnamedWorksheet')}
                      className="object-cover w-full h-full rounded-lg md:rounded-l-lg md:rounded-r-none"
                    />
                  ) : (
                    <FileIcon className="w-full h-full p-4 lg:p-16 rounded-lg md:rounded-l-lg md:rounded-r-none bg-gray-50" />
                  )}
                </Link>
              </div>

              {/* Card Content */}
              <div className="col-span-3 md:col-span-5 flex flex-col justify-between">
                <CardHeader className="p-2 xl:p-4">
                  <CardTitle>
                    <Link
                      to={`/app/my/worksheets/edit/${worksheet.id}`}
                      className="flex items-center"
                    >
                      {/* Privacy Icon */}
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            {worksheet.is_public ? (
                              <Globe
                                className="mr-1 text-green-700 font-bold"
                                size={16}
                              />
                            ) : (
                              <LockIcon
                                className="mr-1 text-yellow-600 font-bold"
                                size={16}
                              />
                            )}
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>
                              {worksheet.is_public
                                ? t('label.public')
                                : t('label.private')}
                            </p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>

                      {/* Title */}
                      <span className="truncate w-full text-xl">
                        {worksheet.name || t('label.unnamedWorksheet')}
                      </span>
                    </Link>
                  </CardTitle>

                  {/* Description */}
                  <p className="truncate w-full text-md">
                    {worksheet.description || t('label.noDescription')}
                  </p>
                </CardHeader>

                {/* Buttons */}
                <CardFooter className="flex space-x-2 p-2 xl:p-4">
                  <Link to={`/app/my/worksheets/edit/${worksheet.id}`}>
                    <Button>{t('button.edit')}</Button>
                  </Link>
                  <WorksheetPrintModal
                    variant="secondary"
                    worksheet={worksheet}
                    buttonText={t('button.generateAssessments')}
                  />
                  <WorksheetPreviewModal
                    variant="secondary"
                    worksheet={worksheet}
                  />
                  <WorksheetDeleteButton
                    onDelete={handleDeleteWorksheet}
                    worksheet={worksheet}
                  >
                    {t('button.delete')}
                  </WorksheetDeleteButton>
                </CardFooter>
              </div>
            </Card>
          ))}
        </div>
      ) : (
        <div className="flex items-center justify-center h-screen ">
          <div className="text-center py-10 px-6 max-w-md">
            <p className="text-lg font-semibold">
              {t('text.noWorksheetsAvailable')}
            </p>
            <p className="mt-2 text-gray-600">{t('text.looksVeryEmpty')}</p>
            <div className="mt-4">
              <WorksheetCreateButton>
                {t('button.createWorksheet')}
              </WorksheetCreateButton>
              <Button variant="outline" className="ml-4">
                {t('button.browseGallery')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WorksheetsList;
