import { LoaderIcon } from 'lucide-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../stores/authStore';

const Logout: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { logout } = useAuthStore();

  logout();
  navigate('/');

  return (
    <div className="flex flex-col items-center min-h-screen">
      <LoaderIcon className="w-12 h-12 text-gray-800 animate-spin mb-10" />
      <h1 className="text-4xl font-bold text-gray-800 mb-4">
        {t('logout.title')}
      </h1>
      <p className="text-lg text-gray-600">{t('logout.message')}</p>
    </div>
  );
};

export default Logout;
