// src/i18n.ts
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

// i18n configuration
i18n
  .use(HttpApi) // load translations using http backend
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // bind i18next to react
  .init({
    fallbackLng: {
      'en-US': ['en'], // If the user's language is 'en-US', fallback to 'en'
      default: ['en'], // Fallback for other languages
    },
    debug: true, // helpful for development
    interpolation: {
      escapeValue: false, // react already escapes values
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json', // path to your translation files
    },
  });

export default i18n;
