import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { AssessainAPI, CancelablePromise, OpenAPIConfig } from '../api';
import { ApiRequestOptions } from '../api/core/ApiRequestOptions';
import { AxiosHttpRequest } from '../api/core/AxiosHttpRequest';
import { request as __request } from '../api/core/request';
import useAuthStore from '../stores/authStore';

const BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

class AuthenticationError extends Error {}

const getApiToken = async (refresh = false) => {
  if (refresh) {
    await useAuthStore.getState().refreshAccessToken();
  }
  const token = await useAuthStore.getState().getAccessToken();

  if (!token) {
    throw new AuthenticationError('Unable to get access token');
  }

  return token;
};

interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  _retry?: boolean;
}

class AuthenticatedHttpRequest extends AxiosHttpRequest {
  private axiosClient: AxiosInstance;
  private isRefreshing = false;
  private failedQueue: {
    resolve: (value?: unknown) => void;
    reject: (reason?: unknown) => void;
  }[] = [];

  constructor(config: OpenAPIConfig) {
    super(config);

    this.axiosClient = axios.create();

    // Attach the interceptors
    this.axiosClient.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error) => this.handleResponseError(error)
    );
  }

  public override request<T>(options: ApiRequestOptions): CancelablePromise<T> {
    return __request(this.config, options, this.axiosClient);
  }

  private async handleResponseError(error: AxiosError) {
    console.log('Error:', error);
    const originalRequest = error.config as CustomAxiosRequestConfig;

    // If error is 401 and we aren't already refreshing
    if (
      originalRequest &&
      error.response?.status === 401 &&
      !originalRequest._retry
    ) {
      if (this.isRefreshing) {
        // Queue failed requests until token refresh is complete
        return new Promise((resolve, reject) => {
          this.failedQueue.push({ resolve, reject });
        }).then((token) => {
          originalRequest.headers['Authorization'] = `Bearer ${token}`;
          return this.axiosClient(originalRequest);
        });
      }

      originalRequest._retry = true;
      this.isRefreshing = true;

      try {
        const token = await this.refreshToken();
        originalRequest.headers['Authorization'] = `Bearer ${token}`;
        this.processQueue(null);
        return this.axiosClient(originalRequest);
      } catch (refreshError) {
        this.processQueue(refreshError);
        throw refreshError;
      } finally {
        this.isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }

  private processQueue(error: unknown) {
    this.failedQueue.forEach((promise) => {
      if (error) promise.reject(error);
      else promise.resolve();
    });
    this.failedQueue = [];
  }

  // Placeholder for the refresh token logic
  private async refreshToken(): Promise<string> {
    const token = await getApiToken(true);
    this.config.TOKEN = token;
    return token;
  }
}

const getApiService = async () => {
  const user = useAuthStore.getState().user;
  const config: Partial<OpenAPIConfig> = {
    BASE: BASE_URL,
    HEADERS: {
      'ngrok-skip-browser-warning': 'true',
    },
  };
  let requestConstructor = AxiosHttpRequest;

  if (user) {
    const token = await getApiToken();
    config.TOKEN = token;
    requestConstructor = AuthenticatedHttpRequest;
  }

  const service = new AssessainAPI(config, requestConstructor);

  return service.default;
};

export default getApiService;
