/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum WorksheetPartType {
    MC = 'mc',
    OPEN = 'open',
    TEXT = 'text',
    IMAGE = 'image',
}
