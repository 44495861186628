// Loading.tsx
import { Loader } from 'lucide-react'; // Import the Loader icon
import React from 'react';

const Loading: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <Loader className="animate-spin h-10 w-10 text-blue-500 mb-4" />
      <p className="text-lg text-gray-600">Loading...</p>
    </div>
  );
};

export default Loading;
