/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssessmentCreateSchema } from '../models/AssessmentCreateSchema';
import type { AssessmentDetailSchema } from '../models/AssessmentDetailSchema';
import type { ExtraFileSchema } from '../models/ExtraFileSchema';
import type { PagedAssessmentSchema } from '../models/PagedAssessmentSchema';
import type { PagedWorksheetSchema } from '../models/PagedWorksheetSchema';
import type { PageSchema } from '../models/PageSchema';
import type { SnapSchema } from '../models/SnapSchema';
import type { UserDisplayNameInSchema } from '../models/UserDisplayNameInSchema';
import type { UserSchema } from '../models/UserSchema';
import type { UserSettingsSchema } from '../models/UserSettingsSchema';
import type { WorksheetDeleteSchema } from '../models/WorksheetDeleteSchema';
import type { WorksheetDetailSchema } from '../models/WorksheetDetailSchema';
import type { WorksheetDetailsPatchSchema } from '../models/WorksheetDetailsPatchSchema';
import type { WorksheetPartPatchSchema } from '../models/WorksheetPartPatchSchema';
import type { WorksheetValiditySchema } from '../models/WorksheetValiditySchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class DefaultService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Index
     * Returns only a welcome message. Can bee used as a health check.
     * @returns any OK
     * @throws ApiError
     */
    public appApiIndex(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/',
        });
    }
    /**
     * Get Assessments List
     * @param limit
     * @param offset
     * @returns PagedAssessmentSchema OK
     * @throws ApiError
     */
    public appApiAssessmentsGetAssessmentsList(
        limit: number = 100,
        offset?: number,
    ): CancelablePromise<PagedAssessmentSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/assessments/list',
            query: {
                'limit': limit,
                'offset': offset,
            },
        });
    }
    /**
     * Get Assessment Detail
     * @param id
     * @returns AssessmentDetailSchema OK
     * @throws ApiError
     */
    public appApiAssessmentsGetAssessmentDetail(
        id: string,
    ): CancelablePromise<AssessmentDetailSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/assessments/getAssessment',
            query: {
                'id': id,
            },
        });
    }
    /**
     * Create New Assesment
     * @param requestBody
     * @returns AssessmentDetailSchema OK
     * @throws ApiError
     */
    public appApiAssessmentsCreateNewAssesment(
        requestBody: AssessmentCreateSchema,
    ): CancelablePromise<AssessmentDetailSchema> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/assessments/createNewAssesment',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete Assessment
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public appApiAssessmentsDeleteAssessment(
        id: string,
    ): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/assessments/deleteAssessment/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Get Page
     * @param slug
     * @returns PageSchema OK
     * @throws ApiError
     */
    public appApiPagesGetPage(
        slug: string,
    ): CancelablePromise<PageSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/pages/getPage',
            query: {
                'slug': slug,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }
    /**
     * Get Snap
     * @param snapId
     * @returns SnapSchema OK
     * @throws ApiError
     */
    public appApiSnapsGetSnap(
        snapId: string,
    ): CancelablePromise<SnapSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/snaps/getSnap',
            query: {
                'snap_id': snapId,
            },
        });
    }
    /**
     * Upload Snap
     * @param snapId
     * @param formData
     * @returns SnapSchema OK
     * @throws ApiError
     */
    public appApiSnapsUploadSnap(
        snapId: string,
        formData: {
            image: Blob;
        },
    ): CancelablePromise<SnapSchema> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/snaps/uploadSnap',
            query: {
                'snap_id': snapId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * Snap Viewed
     * @param snapId
     * @returns void
     * @throws ApiError
     */
    public appApiSnapsSnapViewed(
        snapId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/snaps/snapViewed',
            query: {
                'snap_id': snapId,
            },
        });
    }
    /**
     * Rerun Snap (Superadmin Only)
     * @param snapId
     * @returns void
     * @throws ApiError
     */
    public appApiSnapsRerunSnapSuperadminOnly(
        snapId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/snaps/rerunSnap',
            query: {
                'snap_id': snapId,
            },
        });
    }
    /**
     * List Users (Admin Only)
     * @returns UserSchema OK
     * @throws ApiError
     */
    public appApiUsersListUsersAdminOnly(): CancelablePromise<Array<UserSchema>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users',
        });
    }
    /**
     * Import Users (Superadmin Only)
     * @returns string Created
     * @throws ApiError
     */
    public appApiUsersImportUsersSuperadminOnly(): CancelablePromise<Record<string, string>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/import',
        });
    }
    /**
     * Get Current User Settings
     * @returns UserSettingsSchema OK
     * @throws ApiError
     */
    public appApiUsersGetCurrentUserSettings(): CancelablePromise<UserSettingsSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/currentUserSettings',
        });
    }
    /**
     * Change Current User Display Name
     * @param requestBody
     * @returns UserSettingsSchema OK
     * @throws ApiError
     */
    public appApiUsersChangeCurrentUserDisplayName(
        requestBody: UserDisplayNameInSchema,
    ): CancelablePromise<UserSettingsSchema> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/users/changeCurrentUserDisplayName',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * List Public Worksheets
     * @param limit
     * @param offset
     * @returns PagedWorksheetSchema OK
     * @throws ApiError
     */
    public appApiWorksheetsListPublicWorksheets(
        limit: number = 100,
        offset?: number,
    ): CancelablePromise<PagedWorksheetSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/worksheets/listPublicWorksheets',
            query: {
                'limit': limit,
                'offset': offset,
            },
        });
    }
    /**
     * List Users Worksheets
     * @param limit
     * @param offset
     * @returns PagedWorksheetSchema OK
     * @throws ApiError
     */
    public appApiWorksheetsListUsersWorksheets(
        limit: number = 100,
        offset?: number,
    ): CancelablePromise<PagedWorksheetSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/worksheets/listUsersWorksheets',
            query: {
                'limit': limit,
                'offset': offset,
            },
        });
    }
    /**
     * Get Worksheet
     * @param id
     * @returns WorksheetDetailSchema OK
     * @throws ApiError
     */
    public appApiWorksheetsGetWorksheet(
        id: string,
    ): CancelablePromise<WorksheetDetailSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/worksheets/getWorksheet',
            query: {
                'id': id,
            },
        });
    }
    /**
     * Get Worksheet Validity
     * @param id
     * @returns WorksheetValiditySchema OK
     * @throws ApiError
     */
    public appApiWorksheetsGetWorksheetValidity(
        id: string,
    ): CancelablePromise<WorksheetValiditySchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/worksheets/geWorksheetValidity',
            query: {
                'id': id,
            },
        });
    }
    /**
     * Get Worksheet Preview
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public appApiWorksheetsGetWorksheetPreview(
        id: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/worksheets/getWorksheetPreview',
            query: {
                'id': id,
            },
        });
    }
    /**
     * Get Worksheet Pdf Preview (Superadmin Only)
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public appApiWorksheetsGetWorksheetPdfPreviewSuperadminOnly(
        id: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/worksheets/getWorksheetPdfPreview',
            query: {
                'id': id,
            },
        });
    }
    /**
     * Create Worksheet
     * @param requestBody
     * @returns WorksheetDetailSchema OK
     * @throws ApiError
     */
    public appApiWorksheetsCreateWorksheet(
        requestBody?: (WorksheetDetailsPatchSchema | null),
    ): CancelablePromise<WorksheetDetailSchema> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/worksheets/createWorksheet',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update Worksheet
     * @param id
     * @param requestBody
     * @returns WorksheetDetailSchema OK
     * @throws ApiError
     */
    public appApiWorksheetsUpdateWorksheet(
        id: string,
        requestBody: WorksheetDetailsPatchSchema,
    ): CancelablePromise<WorksheetDetailSchema> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/worksheets/updateWorksheet',
            query: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete Worksheet
     * @param id
     * @returns WorksheetDeleteSchema OK
     * @throws ApiError
     */
    public appApiWorksheetsDeleteWorksheet(
        id: string,
    ): CancelablePromise<WorksheetDeleteSchema> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/worksheets/deleteWorksheet',
            query: {
                'id': id,
            },
        });
    }
    /**
     * Upload Worksheet Cover Image
     * @param id
     * @param formData
     * @returns any OK
     * @throws ApiError
     */
    public appApiWorksheetsUploadWorksheetCoverImage(
        id: string,
        formData: {
            image: Blob;
        },
    ): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/worksheets/uploadWorksheetCoverImage',
            query: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Delete Worksheet Cover Image
     * @param id
     * @returns WorksheetDetailSchema OK
     * @throws ApiError
     */
    public appApiWorksheetsDeleteWorksheetCoverImage(
        id: string,
    ): CancelablePromise<WorksheetDetailSchema> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/worksheets/deleteWorksheetCoverImage',
            query: {
                'id': id,
            },
        });
    }
    /**
     * Upload Worksheet Extra File
     * @param id
     * @param formData
     * @returns ExtraFileSchema OK
     * @throws ApiError
     */
    public appApiWorksheetsUploadWorksheetExtraFile(
        id: string,
        formData: {
            file: Blob;
        },
    ): CancelablePromise<ExtraFileSchema> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/worksheets/uploadWorksheetExtraFile',
            query: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Rename Extra File
     * @param id
     * @param name
     * @returns ExtraFileSchema OK
     * @throws ApiError
     */
    public appApiWorksheetsRenameExtraFile(
        id: string,
        name: string,
    ): CancelablePromise<ExtraFileSchema> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/worksheets/renameExtraFile',
            query: {
                'id': id,
                'name': name,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }
    /**
     * Delete Worksheet Extra File
     * @param id
     * @returns void
     * @throws ApiError
     */
    public appApiWorksheetsDeleteWorksheetExtraFile(
        id: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/worksheets/deleteWorksheetExtraFile',
            query: {
                'id': id,
            },
        });
    }
    /**
     * Create Worksheet Part
     * @param worksheetId
     * @param type
     * @returns WorksheetDetailSchema OK
     * @throws ApiError
     */
    public appApiWorksheetsCreateWorksheetPart(
        worksheetId: string,
        type: 'text' | 'image' | 'mc' | 'open',
    ): CancelablePromise<WorksheetDetailSchema> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/worksheets/createWorksheetPart',
            query: {
                'worksheet_id': worksheetId,
                'type': type,
            },
        });
    }
    /**
     * Update Worksheet Part
     * @param worksheetId
     * @param partId
     * @param requestBody
     * @returns WorksheetDetailSchema OK
     * @throws ApiError
     */
    public appApiWorksheetsUpdateWorksheetPart(
        worksheetId: string,
        partId: string,
        requestBody: WorksheetPartPatchSchema,
    ): CancelablePromise<WorksheetDetailSchema> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/worksheets/updateWorksheetPart',
            query: {
                'worksheet_id': worksheetId,
                'part_id': partId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete Worksheet Part
     * @param worksheetId
     * @param partId
     * @returns WorksheetDetailSchema OK
     * @throws ApiError
     */
    public appApiWorksheetsDeleteWorksheetPart(
        worksheetId: string,
        partId: string,
    ): CancelablePromise<WorksheetDetailSchema> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/worksheets/deleteWorksheetPart',
            query: {
                'worksheet_id': worksheetId,
                'part_id': partId,
            },
        });
    }
    /**
     * Upload Worksheet Part Image
     * @param worksheetId
     * @param partId
     * @param formData
     * @returns WorksheetDetailSchema OK
     * @throws ApiError
     */
    public appApiWorksheetsUploadWorksheetPartImage(
        worksheetId: string,
        partId: string,
        formData: {
            image: Blob;
        },
    ): CancelablePromise<WorksheetDetailSchema> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/worksheets/uploadWorksheetPartImage',
            query: {
                'worksheet_id': worksheetId,
                'part_id': partId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Delete Worksheet Part Image
     * @param worksheetId
     * @param partId
     * @returns WorksheetDetailSchema OK
     * @throws ApiError
     */
    public appApiWorksheetsDeleteWorksheetPartImage(
        worksheetId: string,
        partId: string,
    ): CancelablePromise<WorksheetDetailSchema> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/worksheets/deleteWorksheetPartImage',
            query: {
                'worksheet_id': worksheetId,
                'part_id': partId,
            },
        });
    }
}
