import React from 'react';
import { useNavigate } from 'react-router-dom';
import getApiService from '../../../services/api';
import { Button, ButtonProps } from '../../ui/button';

const WorksheetCreateButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ ...props }) => {
    const navigate = useNavigate();

    const handleCreateWorksheet = async () => {
      const api = await getApiService();

      if (!api) return;

      try {
        // Call the API to create a new worksheet
        const response = await api.appApiWorksheetsCreateWorksheet();

        // Assuming the response has an `id` field for the newly created worksheet
        const newWorksheetId = response.id;

        // Redirect to the WorksheetEdit page with the new worksheet ID
        navigate(`/app/my/worksheets/edit/${newWorksheetId}`);
      } catch (error) {
        console.error('Error creating worksheet:', error);
        // Handle error (e.g., show a toast notification)
      }
    };

    return <Button onClick={handleCreateWorksheet} {...props} />;
  }
);

WorksheetCreateButton.displayName = 'WorksheetCreateButton';

export default WorksheetCreateButton;
