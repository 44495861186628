import React from 'react';
import { Outlet } from 'react-router-dom';
import { Toaster } from '../ui/sonner';

const SnapLayout: React.FC = () => {
  return (
    <>
      <div className="flex flex-1 flex-col gap-4 p-4 pt-0">
        <Outlet />
      </div>
      <Toaster />
    </>
  );
};

export default SnapLayout;
