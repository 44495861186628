/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum SnapErrorType {
    INVALID_QR_CODE = 'invalid_qr_code',
    MISSING_MARKERS = 'missing_markers',
    WORKSHEET_NOT_FOUND = 'worksheet_not_found',
    UNEXPECTED_ERROR = 'unexpected_error',
}
