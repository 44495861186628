// src/pages/Pricing.tsx
import { InfoIcon } from 'lucide-react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Button } from '../components/ui/button';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '../components/ui/card';

type PricingPlan = {
  key: string;
  price: string;
  features: string[];
  popular?: boolean;
  comingSoon?: boolean;
};

type PaymentInfo = {
  title: string;
  content: string[];
};

const Pricing = () => {
  const { t } = useTranslation();

  const pricingPlans: PricingPlan[] = [
    {
      key: 'free',
      price: t('pricing.plans.free.price'),
      features: t('pricing.plans.free.features', {
        returnObjects: true,
      }) as string[],
    },
    {
      key: 'teacher',
      price: t('pricing.plans.teacher.price'),
      features: t('pricing.plans.teacher.features', {
        returnObjects: true,
      }) as string[],
      // popular: true,
      comingSoon: true,
    },
    {
      key: 'school',
      price: t('pricing.plans.school.price'),
      features: t('pricing.plans.school.features', {
        returnObjects: true,
      }) as string[],
      comingSoon: true,
    },
  ];

  const paymentInfo: PaymentInfo = {
    title: t('pricing.payment.title'),
    content: [
      t('pricing.payment.content.0'),
      t('pricing.payment.content.1'),
      t('pricing.payment.content.2'),
      t('pricing.payment.content.3'),
    ],
  };

  return (
    <>
      <Helmet>
        <title>{t('pricing.page.title')}</title>
        <meta name="description" content={t('pricing.page.description')} />
      </Helmet>
      <div className="container mx-auto my-12 px-4">
        <h1 className="text-3xl font-bold text-center mb-8">
          {t('pricing.page.title')}
        </h1>
        <h2 className="text-lg text-center mb-8">
          {t('pricing.page.subtitle')}
        </h2>
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3">
          {pricingPlans.map((plan) => (
            <Card
              key={plan.key}
              className={`shadow-lg transition-transform transform hover:scale-105 ${
                plan.popular
                  ? 'bg-gradient-to-r from-blue-500 to-blue-600 text-white'
                  : 'bg-white'
              }`}
            >
              <CardHeader className={`p-6`}>
                <CardTitle className={`text-2xl font-semibold`}>
                  {t(`pricing.plans.${plan.key}.title`)}
                  {plan.popular && (
                    <span className="bg-red-500 text-white text-xs px-2 py-1 rounded-full ml-2">
                      {t('pricing.labels.popular')}
                    </span>
                  )}
                </CardTitle>
                <div className={`text-lg font-bold mt-2`}>{plan.price}</div>
              </CardHeader>
              <CardContent className="p-4">
                <ul className="space-y-4">
                  {plan.features.map((feature: string, index: number) => (
                    <li key={index} className="flex items-center">
                      <InfoIcon
                        className={`w-5 h-5 ${
                          plan.popular ? 'text-white' : 'text-blue-500'
                        } mr-2`}
                        style={{ flexShrink: 0 }}
                      />
                      {feature}
                    </li>
                  ))}
                </ul>
              </CardContent>
              <CardFooter className="p-6 flex flex-wrap">
                <Button
                  variant="default"
                  className={`w-full py-3 ${
                    plan.popular
                      ? 'bg-white text-blue-600'
                      : 'bg-blue-600 text-white'
                  }`}
                  disabled={plan.comingSoon}
                >
                  {t(`pricing.plans.${plan.key}.callToAction`)}
                </Button>
                {plan.comingSoon && (
                  <div className="w-full">
                    <p className="mt-2 text-xs text-center">
                      {t('label.comingSoon')}
                    </p>
                  </div>
                )}
                {plan.key === 'teacher' && (
                  <div className="w-full">
                    <p className="mt-2 text-xs text-center text-white">
                      {t('pricing.plans.teacher.noCreditCard')}
                    </p>
                  </div>
                )}
              </CardFooter>
            </Card>
          ))}
        </div>

        <div className="mt-12 p-6">
          <h3 className="text-xl font-bold text-center mb-4">
            {paymentInfo.title}
          </h3>
          <ul className="mt-4 space-y-2 text-center">
            {paymentInfo.content.map((item, index) => (
              <li key={index} className="text-sm text-gray-700">
                <InfoIcon className="inline w-4 h-4 text-blue-500 mr-2" />
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Pricing;
