import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';

const CookieBanner = () => {
  const { t } = useTranslation();

  const handleAccept = () => {
    // Logic to set cookies as accepted
    // You might also want to set up your tracking cookies here.
    console.log('Cookies accepted');
  };

  const handleDecline = () => {
    // Logic to handle declined cookies
    console.log('Cookies declined');
  };

  return (
    <CookieConsent
      location="bottom"
      buttonText={t('cookieConsent.accept')}
      declineButtonText={t('cookieConsent.decline')}
      enableDeclineButton
      onAccept={handleAccept}
      onDecline={handleDecline}
      cookieName="cookieConsent"
      expires={150}
    >
      {t('cookieConsent.message')}
      <div>
        {/* Include a link to your cookie policy or more details */}
        <a href="/pages/cookie-policy">{t('cookieConsent.cookiePolicy')}</a>
      </div>
    </CookieConsent>
  );
};

export default CookieBanner;
