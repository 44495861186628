// src/pages/WorksheetEditor.tsx
import * as Sentry from '@sentry/react';
import { t } from 'i18next';
import { AlertCircle, Loader } from 'lucide-react';
import { useEffect, useState } from 'react';
import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import { toast } from 'sonner';
import {
  WorksheetDetailSchema,
  WorksheetErrorType,
  WorksheetPartSchema,
} from '../api';
import WorksheetAddPartPopover from '../components/features/worksheets/WorksheetAddPartPopover';
import WorksheetDetailsForm from '../components/features/worksheets/WorksheetDetailsForm';
import WorksheetImagePart from '../components/features/worksheets/WorksheetImagePart';
import WorksheetMCQuestionPart from '../components/features/worksheets/WorksheetMCQuestionPart';
import WorksheetOpenQuestionPart from '../components/features/worksheets/WorksheetOpenQuestionPart';
import WorksheetTextPart from '../components/features/worksheets/WorksheetTextPart';
import { Alert, AlertDescription, AlertTitle } from '../components/ui/alert';
import getApiService from '../services/api';

// Define the loader function
export const worksheetLoader = async ({ params }: LoaderFunctionArgs) => {
  const api = await getApiService();
  const { id } = params;
  if (!id) {
    throw new Error('Worksheet ID is missing.');
  }

  const worksheet = await api.appApiWorksheetsGetWorksheet(id);
  return worksheet;
};

const WorksheetEditor = () => {
  const [worksheet, setWorksheet] = useState<WorksheetDetailSchema>(
    useLoaderData() as WorksheetDetailSchema
  );
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const getPartComponent = (type: string) => {
    switch (type) {
      case 'text':
        return WorksheetTextPart;
      case 'image':
        return WorksheetImagePart;
      case 'mc':
        return WorksheetMCQuestionPart;
      case 'open':
        return WorksheetOpenQuestionPart;
      default:
        console.log('Unknown part type:', type);
        return null;
    }
  };

  const handleAddPart = async (partType: WorksheetPartSchema.type) => {
    const api = await getApiService();
    if (!api) return;
    const updatedWorksheet = await api.appApiWorksheetsCreateWorksheetPart(
      worksheet.id,
      partType
    );
    setWorksheet(updatedWorksheet);
  };

  const handlePartRemove = (index: number) => {
    const updateWorksheet = async () => {
      const api = await getApiService();
      if (!api) return;
      const part = worksheet.sorted_parts[index];

      try {
        const updatedWorksheet = await api.appApiWorksheetsDeleteWorksheetPart(
          worksheet.id,
          part.id
        );
        setWorksheet(updatedWorksheet);
      } catch (error) {
        toast.error(t('worksheet.edit.error.failedToDeletePart'));
        Sentry.captureException(error);
      }
    };
    updateWorksheet();
  };

  const movePart = (index: number, direction: 'up' | 'down') => {
    const updateWorksheet = async () => {
      const api = await getApiService();
      if (!api) return;
      const newParts = [...worksheet.sorted_parts];
      const [movedPart] = newParts.splice(index, 1);

      if (direction === 'up') {
        newParts.splice(index - 1, 0, movedPart);
      } else {
        newParts.splice(index + 1, 0, movedPart);
      }

      const newPartsOrder = newParts.map((part) => part.id);
      const updatedWorksheet = await api.appApiWorksheetsUpdateWorksheet(
        worksheet.id,
        {
          parts_order: newPartsOrder,
        }
      );

      setWorksheet(updatedWorksheet);
    };

    updateWorksheet();
  };

  useEffect(() => {
    const checkValidity = async () => {
      const api = await getApiService();
      if (!api) return;
      if (!worksheet || !worksheet.id) {
        return;
      }

      const validity = await api.appApiWorksheetsGetWorksheetValidity(
        worksheet.id
      );

      if (validity.is_valid !== null) {
        const updatedWorksheet = await api.appApiWorksheetsGetWorksheet(
          worksheet.id
        );

        if (updatedWorksheet.is_valid === false) {
          toast.error(getValidityErrorMessage(updatedWorksheet.error));
        }

        setWorksheet(updatedWorksheet);
      }
    };

    const intervalCheckValidity = setInterval(checkValidity, 1000);

    if (worksheet.is_valid === false) {
      setIsAlertVisible(true);
      clearInterval(intervalCheckValidity);
    } else if (worksheet.is_valid === true) {
      setIsAlertVisible(false);
      clearInterval(intervalCheckValidity);
    }

    return () => clearInterval(intervalCheckValidity);
  }, [worksheet.is_valid]);

  const getValidityErrorMessage = (error: WorksheetErrorType | null) => {
    switch (error) {
      case WorksheetErrorType.NO_TITLE:
        return t('worksheet.edit.error.noTitle');
      case WorksheetErrorType.NO_QUESTION:
        return t('worksheet.edit.error.noQuestion');
      case WorksheetErrorType.MORE_THAN_ONE_PAGE:
        return t('worksheet.edit.error.couldNotFitToOnePage');
      default:
        return t('worksheet.edit.error.unknown');
    }
  };

  return (
    <div className="mx-auto p-6">
      <h1 className="text-3xl font-bold mb-8 text-center">
        {t('worksheet.edit.title')}
      </h1>

      <div className="grid grid-cols-1 lg:grid-cols-[minmax(200px,1fr)_2fr] 2xl:grid-cols-[minmax(200px,1fr)_minmax(800px,2fr)_2fr] gap-6">
        <WorksheetDetailsForm worksheet={worksheet} onChange={setWorksheet} />

        <div className="flex flex-col border border-gray-300 rounded-lg p-4 mb-20 2xl:max-h-[80vh] 2xl:overflow-y-auto">
          <h2 className="text-xl font-semibold mb-4">
            {t('worksheet.edit.parts')}
          </h2>
          {isAlertVisible && (
            <div className="mb-4">
              <Alert variant="destructive">
                <AlertCircle className="h-4 w-4" />
                <AlertTitle>
                  {t('worksheet.edit.alert.notValidTitle')}
                </AlertTitle>
                <AlertDescription>
                  {worksheet.error ? (
                    getValidityErrorMessage(worksheet.error)
                  ) : (
                    <span className="flex">
                      <Loader className="h-4 w-4 mr-2 animate-spin" />
                      {t('worksheet.edit.alert.validating')}
                    </span>
                  )}
                </AlertDescription>
              </Alert>
            </div>
          )}
          <div className="flex-grow flex flex-col items-center">
            {worksheet.sorted_parts.map((part, index) => {
              const PartComponent = getPartComponent(part.type);
              if (PartComponent) {
                return (
                  <PartComponent
                    key={part.id}
                    worksheetId={worksheet.id}
                    part={part}
                    onMoveUp={() => movePart(index, 'up')}
                    onMoveDown={() => movePart(index, 'down')}
                    onChange={setWorksheet}
                    isFirst={index === 0}
                    isLast={index === worksheet.sorted_parts.length - 1}
                    onRemove={() => handlePartRemove(index)}
                    shouldSetFocus={false}
                  />
                );
              }
            })}
            <WorksheetAddPartPopover
              onAddPart={(type: WorksheetPartSchema.type) =>
                handleAddPart(type)
              }
            />
          </div>
        </div>

        <div className="hidden 2xl:flex flex-col">
          <div className="relative">
            {!isAlertVisible && worksheet.preview_image && (
              <img
                src={worksheet.preview_image}
                alt={t('worksheet.preview.label')}
                className="object-cover w-full rounded-lg shadow-md"
              />
            )}

            {worksheet.is_valid === null && (
              <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-40 rounded-lg">
                <Loader className="h-16 w-16 text-gray-400 animate-spin" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorksheetEditor;
