// src/components/features/worksheets/WorksheetCard.js
import { FileIcon } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WorksheetSchema } from '../../../api';
import { Button } from '../../ui/button';
import { Card, CardContent, CardFooter, CardHeader } from '../../ui/card';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../ui/dialog';
import WorksheetPrintModal from './WorksheetPrintModal';

interface WorksheetCardProps {
  worksheet: WorksheetSchema; // Use the Worksheet type here
}

const WorksheetCard: React.FC<WorksheetCardProps> = ({ worksheet }) => {
  const { t } = useTranslation();
  const [, setIsOpen] = useState(false); // State to manage modal visibility

  const handleCardClick = () => {
    setIsOpen(true);
    window.history.pushState({}, '', `/worksheets/${worksheet.id}`);
  };

  const handleClose = () => {
    setIsOpen(false);
    window.history.replaceState({}, '', '/worksheets/');
  };

  return (
    <Dialog
      onOpenChange={(open: boolean) => {
        if (!open) {
          handleClose(); // Call handleClose if the dialog is closing
        }
      }}
    >
      <DialogTrigger asChild>
        <Card
          className="max-w-sm border rounded-lg shadow-md cursor-pointer"
          onClick={handleCardClick}
        >
          {worksheet.cover_image ? (
            <img
              src={worksheet.cover_image}
              alt={worksheet.name || t('worksheet.imagePlaceholder')}
              className="object-cover w-full h-48 rounded-t-lg"
              loading="lazy"
            />
          ) : (
            <FileIcon className="w-full h-48 p-4 lg:p-16 rounded-l-lg bg-gray-50" />
          )}
          <CardHeader>
            <h2 className="text-lg font-semibold">
              {worksheet.name || t('worksheet.defaultName')}
            </h2>
          </CardHeader>
          <CardContent>
            <p className="text-gray-600">
              {worksheet.description || t('worksheet.defaultDescription')}
            </p>
          </CardContent>
          <CardFooter className="flex justify-between text-sm text-gray-500">
            <span>
              {t('worksheet.details.authorLabel')}:{' '}
              {worksheet.created_by.display_name}
            </span>
          </CardFooter>
        </Card>
      </DialogTrigger>
      <DialogContent className="max-w-3xl w-full bg-white rounded-lg shadow-lg flex flex-col md:flex-row max-h-[65vh] overflow-y-auto">
        {/* Detailed Information */}
        <div className="md:w-1/2 p-4">
          <DialogHeader>
            <DialogTitle className="text-lg font-semibold">
              {worksheet.name || t('worksheet.defaultName')}
            </DialogTitle>
            <DialogDescription className="text-gray-600">
              {worksheet.description || t('worksheet.defaultDescription')}
            </DialogDescription>
          </DialogHeader>
          <p className="text-gray-500">
            {t('worksheet.details.authorLabel')}:{' '}
            {worksheet.created_by.display_name}
          </p>
          {/* Action Buttons */}
          <div className="mt-4">
            <WorksheetPrintModal worksheet={worksheet} />
            <Button variant="secondary" className="ml-2 mr-2">
              {t('button.copy')}
            </Button>
          </div>
        </div>
        {/* Image Preview */}
        <div className="md:w-1/2 p-4 md:mt-0 mt-4">
          <img
            src={worksheet.preview_image || '/a4placeholder.png'}
            alt={worksheet.name || t('worksheet.details.imagePreview')}
            className="object-cover w-full rounded-lg shadow-md"
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default WorksheetCard;
