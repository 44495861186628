// src/components/layout/AppSidebar.tsx
import { ClipboardIcon, FileTextIcon, GridIcon, PlusIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { NavUser } from '../features/users/NavUser';
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from '../ui/sidebar';

export const AppSidebar = () => {
  const { t } = useTranslation();

  const items = [
    {
      title: t('sidebar.createWorksheet'), // Updated key for sidebar context
      url: '/app/my/worksheets/new',
      icon: PlusIcon,
    },
    {
      title: t('sidebar.myWorksheets'), // Updated key for sidebar context
      url: '/app/my/worksheets',
      icon: ClipboardIcon,
    },
    {
      title: t('sidebar.myAssessments'), // Updated key for sidebar context
      url: '/app/my/assessments',
      icon: FileTextIcon,
    },
    {
      title: t('sidebar.worksheetGallery'), // Updated key for sidebar context
      url: '/worksheets',
      icon: GridIcon,
    },
  ];

  return (
    <Sidebar>
      <SidebarHeader />
      <SidebarContent>
        <SidebarGroup>
          <SidebarGroupLabel>
            {t('sidebar.groupLabelApplication')}
          </SidebarGroupLabel>{' '}
          {/* Added translation for 'Application' label */}
          <SidebarGroupContent>
            <SidebarMenu>
              {items.map((item) => (
                <SidebarMenuItem key={item.title}>
                  <SidebarMenuButton asChild>
                    <Link to={item.url}>
                      <item.icon />
                      <span>{item.title}</span>
                    </Link>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              ))}
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
        <SidebarGroup />
        <SidebarGroup />
      </SidebarContent>
      <SidebarFooter>
        <NavUser />
      </SidebarFooter>
    </Sidebar>
  );
};
