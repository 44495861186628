// App.tsx
import { AuthKitProvider } from '@workos-inc/authkit-react';
import React, { lazy, Suspense } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Loading from './components/common/Loading';
import AppLayout from './components/layout/AppLayout';
import BaseLayout from './components/layout/BaseLayout';
import SnapLayout from './components/layout/SnapLayout';
import AssessmentDetail, {
  assessmentDetailLoader,
} from './pages/AssessmentDetail';
import AssessmentsList, {
  assessmentsListLoader,
} from './pages/AssessmentsList';
import AuthCallback from './pages/AuthCallback';
import ErrorPage from './pages/ErrorPage';
import HomePage from './pages/HomePage';
import Logout from './pages/Logout';
import Page, { pageLoader } from './pages/Page';
import Pricing from './pages/Pricing';
import SnapUpload, { snapLoader } from './pages/SnapUpload';
import { userSettingsLoader } from './pages/UserSettings';
import { worksheetLoader } from './pages/WorksheetEditor';
import { worksheetsGalleryLoader } from './pages/WorksheetsGallery';
import { worksheetsGalleryDetailLoader } from './pages/WorksheetsGalleryDetail';
import { worksheetsLoader } from './pages/WorksheetsList';

// Lazy load your components
const UserSettings = lazy(() => import('./pages/UserSettings'));
const WorksheetEditor = lazy(() => import('./pages/WorksheetEditor'));
const WorksheetNew = lazy(() => import('./pages/WorksheetNew'));
const WorksheetsGallery = lazy(() => import('./pages/WorksheetsGallery'));
const WorksheetsGalleryDetail = lazy(
  () => import('./pages/WorksheetsGalleryDetail')
);
const WorksheetsList = lazy(() => import('./pages/WorksheetsList'));

const WORKOS_CLIENT_ID = process.env.REACT_APP_WORKOS_CLIENT_ID || '';
const WORKOS_REDIRECT_URI = process.env.REACT_APP_WORKOS_REDIRECT_URI || '';

// Create router
const router = createBrowserRouter([
  {
    path: '/auth/callback',
    element: <AuthCallback />,
  },
  // Unauthenticated routes
  {
    path: '/',
    element: <BaseLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: 'worksheets',
        element: (
          <Suspense fallback={<Loading />}>
            <WorksheetsGallery />
          </Suspense>
        ),
        loader: worksheetsGalleryLoader,
      },
      {
        path: 'worksheets/:id',
        element: (
          <Suspense fallback={<Loading />}>
            <WorksheetsGalleryDetail />
          </Suspense>
        ),
        loader: worksheetsGalleryDetailLoader,
      },
      {
        path: 'pricing/',
        element: <Pricing />,
      },
      {
        path: 'page/:slug',
        element: <Page />,
        loader: pageLoader,
      },
      { path: '*', element: <ErrorPage /> }, // Catch-all for 404
    ],
  },
  {
    path: '/snap/',
    element: <SnapLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'upload/:id',
        element: <SnapUpload />,
        loader: snapLoader,
      },
      { path: '*', element: <ErrorPage /> }, // Catch-all for 404
    ],
  },
  // Authenticated routes
  {
    path: '/app/',
    element: <AppLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'my/assessments',
        element: (
          <Suspense fallback={<Loading />}>
            <AssessmentsList />
          </Suspense>
        ),
        loader: assessmentsListLoader,
      },
      {
        path: 'my/assessments/:id',
        element: (
          <Suspense fallback={<Loading />}>
            <AssessmentDetail />
          </Suspense>
        ),
        loader: assessmentDetailLoader,
      },
      {
        path: 'my/worksheets',
        element: (
          <Suspense fallback={<Loading />}>
            <WorksheetsList />
          </Suspense>
        ),
        loader: worksheetsLoader,
      },
      {
        path: 'my/worksheets/new',
        element: (
          <Suspense fallback={<Loading />}>
            <WorksheetNew />
          </Suspense>
        ),
      },
      {
        path: 'my/worksheets/edit/:id',
        element: (
          <Suspense fallback={<Loading />}>
            <WorksheetEditor />
          </Suspense>
        ),
        loader: worksheetLoader,
      },
      {
        path: 'my/settings',
        element: (
          <Suspense fallback={<Loading />}>
            <UserSettings />
          </Suspense>
        ),
        loader: userSettingsLoader,
      },
      {
        path: 'my/logout',
        element: <Logout />,
      },
    ],
  },
]);

const App: React.FC = () => {
  return (
    <AuthKitProvider
      clientId={WORKOS_CLIENT_ID}
      redirectUri={WORKOS_REDIRECT_URI}
    >
      <RouterProvider router={router} />
    </AuthKitProvider>
  );
};

export default App;
