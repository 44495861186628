import { debounce } from 'lodash';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router-dom';
import { UserSettingsSchema } from '../api';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '../components/ui/card';
import { Input } from '../components/ui/input';
import getApiService from '../services/api';

export const userSettingsLoader = async () => {
  const api = await getApiService();
  if (!api) return;
  const userSettings = await api.appApiUsersGetCurrentUserSettings();
  return userSettings;
};

const UserSettings = () => {
  const { t } = useTranslation();
  const userSettings = useLoaderData() as UserSettingsSchema;

  // Local state for the display name input field
  const [displayName, setDisplayName] = useState(
    userSettings.user.display_name
  );
  const [isSaving, setIsSaving] = useState(false); // To handle the loading state

  const saveDisplayName = useCallback(
    debounce(async (newDisplayName: string) => {
      const api = await getApiService();
      if (!api) return;
      setIsSaving(true);
      try {
        // Call API to update displayName
        await api.appApiUsersChangeCurrentUserDisplayName({
          display_name: newDisplayName,
        });
        console.log('Display name updated successfully!');
      } catch (error) {
        console.error('Error updating display name:', error);
      } finally {
        setIsSaving(false);
      }
    }, 500), // Debounce delay (500ms)
    [debounce, setIsSaving]
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDisplayName = e.target.value;
    setDisplayName(newDisplayName); // Update local state
    saveDisplayName(newDisplayName); // Trigger debounced save function
  };

  return (
    <div>
      <Card className="w-[350px]">
        <CardHeader>
          <CardTitle>{t('title.userSettings')}</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid w-full items-center gap-4">
            <div className="flex flex-col space-y-1.5">
              <label
                htmlFor="displayName"
                className="block text-sm font-medium"
              >
                {t('label.displayName')}
              </label>
              <Input
                id="displayName"
                value={displayName}
                onChange={handleInputChange}
              />
              {isSaving && <p className="text-sm text-gray-500">Saving...</p>}{' '}
              {/* Optional saving indicator */}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default UserSettings;
