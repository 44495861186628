import { useAuth } from '@workos-inc/authkit-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../stores/authStore';

const AuthCallback: React.FC = () => {
  const { user, isLoading, getAccessToken } = useAuth();
  const navigate = useNavigate();
  const login = useAuthStore((state) => state.login);

  useEffect(() => {
    const handleLogin = async () => {
      if (user) {
        login(user, await getAccessToken());
        const redirectUrl = localStorage.getItem('redirectUrl');
        console.log('redirectUrl', redirectUrl);

        if (redirectUrl) {
          localStorage.removeItem('redirectUrl');
        }

        if (
          !redirectUrl ||
          redirectUrl === '/auth/callback' ||
          redirectUrl === '/'
        ) {
          navigate('/app/', { replace: true });
        } else {
          navigate(redirectUrl, { replace: true });
        }
      } else {
        navigate('/', { replace: true });
      }
    };
    if (!isLoading) {
      handleLogin();
    }
  }, [user, navigate, isLoading]);

  return <p>Loading...</p>;
};

export default AuthCallback;
