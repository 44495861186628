import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import getApiService from '../../../services/api';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../ui/select';
import WorksheetPartWrapper, {
  WorksheetPartWrapperProps,
} from './WorksheetPartWrapper';

interface WorksheetOpenQuestionData {
  goodAnswer?: string;
  maxPoints?: number;
  fieldSize?: 'small' | 'medium' | 'large';
}

const WorksheetOpenQuestionPart: React.FC<WorksheetPartWrapperProps> = ({
  ...props
}) => {
  const { t } = useTranslation();

  const [part] = useState(props.part);
  const [question, setQuestion] = useState(part.text ?? '');
  const [goodAnswer, setGoodAnswer] = useState(part.data?.goodAnswer ?? '');
  const [maxPoints, setMaxPoints] = useState(part.data?.maxPoints ?? 0);
  const [fieldSize, setFieldSize] = useState<'small' | 'medium' | 'large'>(
    part.data?.fieldSize ?? 'medium'
  );

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus(); // Focus the input
    }
  }, []);

  const debouncedOnChange = useRef(
    _.debounce((text: string, data: WorksheetOpenQuestionData) => {
      const updatePart = async () => {
        const api = await getApiService();
        if (!api) return;
        const updatedWorksheet = await api.appApiWorksheetsUpdateWorksheetPart(
          props.worksheetId,
          part.id,
          {
            text,
            data,
          }
        );
        props.onChange(updatedWorksheet);
      };
      updatePart();
    }, 500)
  ).current;

  const handleQuestionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuestion = e.target.value;
    setQuestion(newQuestion);
    debouncedOnChange(newQuestion, { goodAnswer, maxPoints, fieldSize });
  };

  const handleGoodAnswerChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setGoodAnswer(e.target.value);
    debouncedOnChange(question, {
      goodAnswer: e.target.value,
      maxPoints,
      fieldSize,
    });
  };

  const handleMaxPointsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMaxPoints(Number(e.target.value));
    debouncedOnChange(question, {
      goodAnswer,
      maxPoints: Number(e.target.value),
      fieldSize,
    });
  };

  const handleFieldSizeChange = (size: 'small' | 'medium' | 'large') => {
    setFieldSize(size);
    debouncedOnChange(question, { goodAnswer, maxPoints, fieldSize: size });
  };

  return (
    <WorksheetPartWrapper {...props}>
      {/* Description */}
      <div className="mb-4 text-xs text-gray-600">
        {t('worksheet.openQuestion.description')}
      </div>

      {/* Question Input */}
      <div className="mb-4">
        <input
          ref={inputRef}
          type="text"
          value={question}
          onChange={handleQuestionChange}
          className="border border-gray-400 rounded-lg p-2 w-full"
          placeholder={t('worksheet.openQuestion.placeholder')}
        />
      </div>

      {/* Good Answer Criteria Input */}
      <div className="mb-4">
        <textarea
          value={goodAnswer}
          onChange={handleGoodAnswerChange}
          className="border border-gray-400 rounded-lg p-2 w-full"
          placeholder={t('worksheet.openQuestion.goodAnswerPlaceholder')}
        />
      </div>

      {/* Maximum Points Input */}
      <div className="mb-4 flex items-center">
        <div className="mr-4">
          <input
            type="number"
            value={maxPoints}
            onChange={handleMaxPointsChange}
            className="border border-gray-400 rounded-lg p-2 w-20"
            placeholder={t('worksheet.openQuestion.maxPointsPlaceholder')}
          />
          <span className="ml-1">pts</span>
        </div>

        <Select onValueChange={handleFieldSizeChange} defaultValue={fieldSize}>
          <SelectTrigger className="w-[180px]">
            <SelectValue
              placeholder={t('worksheet.openQuestion.answerFieldSize')}
            />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="small">
              {t('worksheet.openQuestion.size.small')}
            </SelectItem>
            <SelectItem value="medium">
              {t('worksheet.openQuestion.size.medium')}
            </SelectItem>
            <SelectItem value="large">
              {t('worksheet.openQuestion.size.large')}
            </SelectItem>
          </SelectContent>
        </Select>
      </div>
    </WorksheetPartWrapper>
  );
};

export default WorksheetOpenQuestionPart;
