import _ from 'lodash';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import {
  WorksheetDetailSchema,
  WorksheetDetailsPatchSchema,
} from '../../../api';
import getApiService from '../../../services/api';
import { AutosizeTextarea } from '../../ui/autosize-textarea';
import { Button } from '../../ui/button';
import { Input } from '../../ui/input';
import { Switch } from '../../ui/switch';
import WorksheetCoverUploader from './WorksheetCoverUploader';
import WorksheetExtraFileUploader from './WorksheetExtraFileUploader';
import WorksheetPreviewModal from './WorksheetPreviewModal';
import WorksheetPrintModal from './WorksheetPrintModal';

interface WorksheetDetailsFormProps {
  worksheet: WorksheetDetailSchema;
  onChange: (worksheet: WorksheetDetailSchema) => void;
}

const WorksheetDetailsForm: React.FC<WorksheetDetailsFormProps> = ({
  worksheet,
  onChange,
}) => {
  const { t } = useTranslation();
  const [localWorksheet] = useState<WorksheetDetailSchema>(worksheet);
  const [isPublic, setIsPublic] = useState(
    worksheet.is_public !== null ? worksheet.is_public : true
  );
  const [updatedDetails, setUpdatedDetails] =
    useState<WorksheetDetailsPatchSchema>({
      name: worksheet.name,
      description: worksheet.description,
      is_public: isPublic,
    });

  // Memoize the debounced function to avoid re-creating it on every render
  const debouncedOnChange = useRef(
    _.debounce(async (updatedDetails: WorksheetDetailsPatchSchema) => {
      const api = await getApiService();
      if (!api) return;
      const updatedWorksheet = await api.appApiWorksheetsUpdateWorksheet(
        worksheet.id,
        updatedDetails
      );
      onChange(updatedWorksheet);
      toast.success(t('worksheet.edit.detailsSaved'));
    }, 500)
  ).current;

  const handleChange = (key: string, value: string | null) => {
    const newDetails = { ...updatedDetails, [key]: value };
    setUpdatedDetails(newDetails);
    debouncedOnChange(newDetails);
  };

  return (
    <>
      {/* Editing Details Section */}
      <div className="flex flex-col">
        <h2 className="text-xl font-semibold mb-4 flex items-center">
          {t('worksheet.edit.details')}
        </h2>
        <div className="mb-4">
          <Input
            value={updatedDetails.name || ''}
            onChange={(e) => {
              handleChange('name', e.target.value);
            }}
            placeholder={t('worksheet.edit.namePlaceholder')}
          />
        </div>
        <AutosizeTextarea
          value={updatedDetails.description || ''}
          onChange={(e) => {
            handleChange('description', e.target.value);
          }}
          placeholder={t('worksheet.edit.descriptionPlaceholder')}
          rows={5}
        />

        <div className="flex flex-col my-4">
          <WorksheetCoverUploader
            worksheetId={worksheet.id}
            uploadedImage={worksheet.cover_image || null}
          />
        </div>

        <div className="flex flex-col my-4">
          <WorksheetExtraFileUploader
            worksheetId={worksheet.id}
            file={worksheet.extra_file}
          />
        </div>

        <div className="flex items-center mt-4">
          <label className="mr-4">{t('worksheet.edit.isPublic')}</label>
          <Switch
            checked={isPublic}
            onCheckedChange={() => {
              setIsPublic(!isPublic);
              handleChange('is_public', `${!isPublic}`);
            }} // Toggle between public and private
          />
        </div>

        <div className="text-xs text-gray-700">
          <div className="flex items-center mt-4">
            <label className="mr-4">{t('worksheet.edit.createdBy')}</label>
            <p>{localWorksheet.created_by.display_name}</p>
          </div>

          <div className="flex items-center mt-2">
            <label className="mr-4">{t('worksheet.edit.createdAt')}</label>
            <p>{new Date(localWorksheet.created_at).toLocaleString()}</p>
          </div>

          {localWorksheet.updated_at && localWorksheet.updated_by && (
            <>
              <div className="flex items-center mt-4">
                <label className="mr-4">{t('worksheet.edit.updatedBy')}</label>
                <p>{localWorksheet.created_by.display_name}</p>
              </div>

              <div className="flex items-center mt-2">
                <label className="mr-4">{t('worksheet.edit.updatedAt')}</label>
                <p>{new Date(localWorksheet.updated_at).toLocaleString()}</p>
              </div>
            </>
          )}
        </div>

        <div className="flex flex-col mt-8">
          <WorksheetPrintModal
            worksheet={worksheet}
            buttonText={t('worksheet.button.generateAssessments')}
          />

          <WorksheetPreviewModal
            worksheet={worksheet}
            className="mt-4 2xl:hidden"
          />

          <Button variant="destructive" className="mt-4">
            {t('worksheet.button.delete')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default WorksheetDetailsForm;
