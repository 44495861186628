/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type WorksheetPartSchema = {
    id: string;
    type: WorksheetPartSchema.type;
    text: (string | null);
    data: (Record<string, any> | null);
    image?: (string | null);
};
export namespace WorksheetPartSchema {
    export enum type {
        TEXT = 'text',
        IMAGE = 'image',
        MC = 'mc',
        OPEN = 'open',
    }
}

