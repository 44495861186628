import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { isRouteErrorResponse, Link, useRouteError } from 'react-router-dom';
import { ApiError } from '../api';
import SignInButton from '../components/common/SignInButton';
import { Button } from '../components/ui/button';

const ErrorPage: React.FC = () => {
  const { t } = useTranslation();
  const error = useRouteError();

  Sentry.captureException(error); // Send error to Sentry

  console.error('Error: ', error);

  if (error instanceof ApiError && error.status === 401) {
    return (
      <div className="mt-20 text-center">
        <h1 className="text-5xl font-extrabold text-red-600 mb-4">
          {t('error.unauthorized.title')}
        </h1>
        <p className="text-lg text-gray-800 mb-2">
          {t('error.unauthorized.message')}
        </p>
        <Button asChild variant="default">
          <SignInButton />
        </Button>
      </div>
    );
  } else {
    return (
      <div className="mt-20 text-center">
        {' '}
        {/* Adjust this margin to move content up */}
        <h1 className="text-5xl font-extrabold text-red-600 mb-4">Oops!</h1>
        <p className="text-lg text-gray-800 mb-2">
          {error instanceof ApiError
            ? `Error (${error.status}): ${error.message}`
            : isRouteErrorResponse(error)
            ? `Error (${
                error.statusText || error.status
              }): We didn't find this page.`
            : 'Sorry, an unexpected error has occurred.'}
        </p>
        <p className="text-gray-600 mb-6">
          Please try refreshing the page or returning to the homepage.
        </p>
        <Button asChild variant="default">
          <Link to="/">Go to Homepage</Link>
        </Button>
      </div>
    );
  }
};

export default ErrorPage;
