import * as Sentry from '@sentry/react';
import { createClient } from '@workos-inc/authkit-js';
import { useAuth } from '@workos-inc/authkit-react';
import { create, StateCreator } from 'zustand';
import { createJSONStorage, persist, PersistOptions } from 'zustand/middleware';
import { OpenAPI } from '../api';

type User = ReturnType<typeof useAuth>['user'];

const ACCESS_TOKEN_TTL = 10 * 60 * 1000; // 10 minutes
const WORKOS_CLIENT_ID = process.env.REACT_APP_WORKOS_CLIENT_ID || '';
const WORKOS_REDIRECT_URI = process.env.REACT_APP_WORKOS_REDIRECT_URI || '';

interface AuthState {
  user: User;
  token: string | null;
  tokenExpires: number;
  isAuthenticated: boolean;

  login: (user: User, token: string) => boolean;
  logout: () => Promise<void>;
  getAccessToken: () => Promise<string | void>;
  refreshAccessToken: () => Promise<string | void>;
}

// Persist middleware options
type MyPersist = (
  config: StateCreator<AuthState>,
  options: PersistOptions<AuthState>
) => StateCreator<AuthState>;

const getClient = async () => {
  const client = await createClient(WORKOS_CLIENT_ID, {
    redirectUri: WORKOS_REDIRECT_URI,
  });
  return client;
};

const useAuthStore = create<AuthState>(
  (persist as MyPersist)(
    (set, get) => ({
      user: null as User,
      token: null as string | null,
      tokenExpires: 0,
      isAuthenticated: false,

      login: (user: User, token: string) => {
        console.log('Logging in', user);
        if (!user || !token) {
          return false;
        }

        const tokenExpires = Date.now() + ACCESS_TOKEN_TTL;
        OpenAPI.TOKEN = token;
        Sentry.setUser({ id: user.id, email: user.email });

        set({ user, token, tokenExpires, isAuthenticated: true });
        return true;
      },

      logout: async () => {
        console.log('Logging out');
        (await getClient()).signOut();
        Sentry.setUser(null); // Clear Sentry user on logout
        set({
          user: null,
          token: null,
          tokenExpires: 0,
          isAuthenticated: false,
        });
      },

      getAccessToken: async () => {
        const { token, tokenExpires, refreshAccessToken } = get();

        if (!token || !tokenExpires || Date.now() > tokenExpires) {
          return await refreshAccessToken();
        }

        return token;
      },

      refreshAccessToken: async () => {
        const client = await getClient();
        const user = client.getUser();

        if (!user) {
          await client.signIn();
          return;
        }

        const token = await client.getAccessToken();

        const expires = Date.now() + ACCESS_TOKEN_TTL;
        set({ user, token, tokenExpires: expires });
        return token;
      },
    }),
    {
      name: 'auth-storage',
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default useAuthStore;
