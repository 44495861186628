// src/pages/WorksheetsGalleryDetail.tsx
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Params, useLoaderData } from 'react-router-dom';
import { WorksheetDetailSchema } from '../api';
import WorksheetPrintModal from '../components/features/worksheets/WorksheetPrintModal';
import { Button } from '../components/ui/button';
import getApiService from '../services/api';

// Define the loader function
export const worksheetsGalleryDetailLoader = async ({
  params,
}: {
  params: Params<'id'>;
}) => {
  const { id } = params;
  const api = await getApiService();
  const worksheet = await api.appApiWorksheetsGetWorksheet(id || '');
  return worksheet;
};

// Component that uses the loader
const WorksheetsGalleryDetail = () => {
  const { t } = useTranslation();
  const worksheet = useLoaderData() as WorksheetDetailSchema;

  return (
    <div className="flex flex-col md:flex-row items-start justify-center p-8">
      <Helmet>
        <title>{t('worksheet.galleryDetail.title')}</title>
        <meta
          name="description"
          content={t('worksheet.galleryDetail.description')}
        />
      </Helmet>

      {/* Image Preview */}
      <div className="w-full md:w-1/2 p-4">
        <img
          src={worksheet.preview_image || '/a4placeholder.png'}
          alt={worksheet.name || t('label.worksheetPreview')}
          className="object-cover w-full rounded-lg shadow-md"
        />
      </div>

      {/* Detailed Information */}
      <div className="w-full md:w-1/2 p-4 flex flex-col justify-center">
        <h1 className="text-2xl font-bold mb-2">
          {worksheet.name || t('label.unnamedWorksheet')}
        </h1>
        <p className="text-gray-700 mb-4">
          {worksheet.description || t('label.noDescription')}
        </p>
        <p className="text-gray-500 mb-4">
          {t('label.author')}: {worksheet.created_by.display_name}
        </p>

        {/* Action Buttons */}
        <div className="mt-4 flex space-x-2">
          <WorksheetPrintModal worksheet={worksheet} />
          <Button variant="secondary">{t('action.copy')}</Button>
        </div>
      </div>
    </div>
  );
};

export default WorksheetsGalleryDetail;
