import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router-dom';
import { PagedWorksheetSchema } from '../api';
import WorksheetCard from '../components/features/worksheets/WorksheetCard';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../components/ui/select';
import getApiService from '../services/api';

// Define the loader function
export const worksheetsGalleryLoader = async () => {
  const api = await getApiService();
  const worksheets = await api.appApiWorksheetsListPublicWorksheets();
  return worksheets;
};

// Component that uses the loader
const WorksheetsGallery = () => {
  const { t } = useTranslation();
  const worksheets = useLoaderData() as PagedWorksheetSchema; // Use type assertion

  return (
    <div className="p-4 justify-center flex">
      <div className="max-w-screen-2xl">
        <Helmet>
          <title>{t('worksheetgallery.title')}</title>
          <meta
            name="description"
            content={t('worksheetgallery.description')}
          />
        </Helmet>
        {/* Large heading */}
        <h1 className="text-3xl font-bold text-center mt-16 mb-8">
          {t('title.worksheetsGallery')}
        </h1>

        {/* Description */}
        <p className="text-center mb-8">{t('description.worksheetsGallery')}</p>

        {/* Filtering Tools */}
        <div className="flex justify-center space-x-4 mb-8">
          <Select>
            <SelectTrigger>
              <SelectValue placeholder="Subject" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="math">Math</SelectItem>
              <SelectItem value="science">Science</SelectItem>
              <SelectItem value="history">History</SelectItem>
              <SelectItem value="language">Language</SelectItem>
              {/* Add more subjects as needed */}
            </SelectContent>
          </Select>

          <Select>
            <SelectTrigger>
              <SelectValue placeholder="Grade" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="1">Grade 1</SelectItem>
              <SelectItem value="2">Grade 2</SelectItem>
              <SelectItem value="3">Grade 3</SelectItem>
              <SelectItem value="4">Grade 4</SelectItem>
              {/* Add more grades as needed */}
            </SelectContent>
          </Select>

          <Select>
            <SelectTrigger>
              <SelectValue placeholder="Language" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="english">English</SelectItem>
              <SelectItem value="spanish">Spanish</SelectItem>
              <SelectItem value="french">French</SelectItem>
              <SelectItem value="german">German</SelectItem>
              {/* Add more languages as needed */}
            </SelectContent>
          </Select>
        </div>

        {/* Worksheets Grid */}
        {worksheets?.items.length ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4">
            {worksheets.items.map((worksheet) => (
              <WorksheetCard key={worksheet.id} worksheet={worksheet} />
            ))}
          </div>
        ) : (
          <p>No worksheets available.</p>
        )}
      </div>
    </div>
  );
};

export default WorksheetsGallery;
