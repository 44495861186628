// src/components/features/worksheets/WorksheetPartWrapper.tsx
import { ArrowDown, ArrowUp, Trash2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { WorksheetDetailSchema, WorksheetPartSchema } from '../../../api';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '../../ui/alert-dialog';

interface WorksheetPartWrapperProps {
  worksheetId: string;
  part: WorksheetPartSchema;
  onMoveUp: () => void;
  onMoveDown: () => void;
  onRemove: () => void;
  onChange: (worksheet: WorksheetDetailSchema) => void;
  isFirst: boolean;
  isLast: boolean;
  shouldSetFocus: boolean;
  children?: React.ReactNode;
}

const WorksheetPartWrapper: React.FC<WorksheetPartWrapperProps> = ({
  children,
  ...props
}) => {
  const { t } = useTranslation(); // Use translation hook

  // Function to get the type label based on the type with translation
  const getTypeLabel = () => {
    switch (props.part.type) {
      case 'text':
        return t('worksheet.partType.text'); // Translation key for text part
      case 'image':
        return t('worksheet.partType.image'); // Translation key for image part
      case 'mc':
        return t('worksheet.partType.multipleChoice'); // Updated key for multiple choice
      case 'open':
        return t('worksheet.partType.openQuestion'); // Updated key for open question
      default:
        return t('worksheet.partType.unknown'); // Translation key for unknown part type
    }
  };

  // Function to handle part removal confirmation
  const onPartRemove = () => {
    // Trigger confirmation dialog and remove part if confirmed
    return (
      <AlertDialog>
        <AlertDialogTrigger asChild>
          <button
            className="p-1 bg-gray-100 rounded-full hover:bg-red-300"
            aria-label={t('worksheet.action.removePart')} // Key for aria-label
          >
            <Trash2 size={12} />
          </button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              {t('worksheet.dialog.confirmDeleteTitle')}{' '}
              {/* Title for delete confirmation */}
            </AlertDialogTitle>
            <AlertDialogDescription>
              {t('worksheet.dialog.confirmDeleteDescription')}{' '}
              {/* // Description for delete confirmation */}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>
              {t('action.cancel')} {/* // General cancel action key */}
            </AlertDialogCancel>
            <AlertDialogAction onClick={props.onRemove}>
              {t('action.confirm')} {/* // General confirm action key */}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    );
  };

  return (
    <div className="w-full bg-white border border-gray-300 rounded-lg p-4 mb-4 flex flex-col">
      {/* Header with type label and Up/Down Arrow controls */}
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center space-x-2">
          {/* Remove button with confirmation */}
          {onPartRemove()}
          <h5 className="text-sm font-semibold">{getTypeLabel()}</h5>{' '}
          {/* Smaller heading */}
        </div>

        <div className="flex space-x-2">
          <button
            onClick={props.onMoveUp}
            disabled={props.isFirst}
            className={`p-1 bg-gray-200 rounded-full hover:bg-gray-300 ${
              props.isFirst && 'opacity-20 cursor-not-allowed'
            }`}
            aria-label={t('worksheet.action.moveUp')} // Key for moving part up
          >
            <ArrowUp size={16} />
          </button>
          <button
            onClick={props.onMoveDown}
            disabled={props.isLast}
            className={`p-1 bg-gray-200 rounded-full hover:bg-gray-300 ${
              props.isLast && 'opacity-20 cursor-not-allowed'
            }`}
            aria-label={t('worksheet.action.moveDown')} // Key for moving part down
          >
            <ArrowDown size={16} />
          </button>
        </div>
      </div>

      {/* Content of the part */}
      <div className="w-full text-sm">{children}</div>
    </div>
  );
};

export default WorksheetPartWrapper;
export type { WorksheetPartWrapperProps };
