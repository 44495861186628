import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  SnapProcessedQuestion,
  SnapSchema,
  WorksheetPartType,
} from '../../../api';
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from '../../ui/table';

interface SnapResultsTableProps {
  snap: SnapSchema;
}

const SnapResultsTable: React.FC<SnapResultsTableProps> = ({ snap }) => {
  const { t } = useTranslation();

  if (!snap.data) {
    return <p>{t('snap.resultsTable.noData.errorMessage')}</p>;
  }

  const getMcQuestionDetails = (question: SnapProcessedQuestion) => {
    console.log(question.checked_answer_n);
    console.log(question.checked_answer_n == -1);
    if (!question.checked_answer_n) {
      return <i>{t('snap.resultsTable.noCheckedAnswer.label')}</i>;
    } else if (question.checked_answer_n == -1) {
      return <i>{t('snap.resultsTable.multipleAnswers.label')}</i>;
    } else {
      return (
        <span>
          {t('snap.resultsTable.checkedAnswer.label')}: (
          {question.checked_answer_n}) {question.checked_answer_text}
        </span>
      );
    }
  };

  const getOpenQuestionDetails = (question: SnapProcessedQuestion) => {
    if (question.points_explanation) {
      return question.points_explanation;
    } else {
      return t('snap.resultsTable.noExplanation.label');
    }
  };

  return (
    <div className="width-full flex flex-col items-center justify-center">
      <Table className="max-w-screen-md mx-auto">
        <TableCaption>{t('snap.resultsTable.tableTitle')}</TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[100px]">
              {t('snap.resultsTable.questionNumber.label')}
            </TableHead>
            <TableHead>{t('snap.resultsTable.details.label')}</TableHead>
            <TableHead>{t('snap.resultsTable.points.label')}</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {snap.data.questions.map((question, index) => (
            <TableRow key={index}>
              <TableCell>{question.n}</TableCell>
              <TableCell>
                {question.type === WorksheetPartType.MC &&
                  getMcQuestionDetails(question)}
                {question.type === WorksheetPartType.OPEN &&
                  getOpenQuestionDetails(question)}
              </TableCell>
              <TableCell>{question.points || 0}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={2} className="text-right">
              {t('snap.resultsTable.totalPoints.label')}
            </TableCell>
            <TableCell>{snap.score || 0}</TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  );
};

export default SnapResultsTable;
