import { Loader } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { WorksheetSchema } from '../../../api';
import { Button, ButtonProps } from '../../ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../ui/dialog';

// Props interface for the modal
interface WorksheetPreviewModalProps extends ButtonProps {
  worksheet: WorksheetSchema;
}

// WorksheetPrintModal Component
const WorksheetPreviewModal = ({
  worksheet,
  ...props
}: WorksheetPreviewModalProps) => {
  const { t } = useTranslation(); // Initialize translation hook

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button {...props} disabled={!worksheet.is_valid}>
          {worksheet.is_valid === null && (
            <Loader className="h-4 w-4 mr-2 animate-spin" />
          )}
          {t('worksheet.button.preview')}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('label.worksheetPreview')}</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          {worksheet.preview_image && (
            <img
              src={worksheet.preview_image}
              alt={t('label.worksheetPreview')}
              className="object-cover w-full rounded-lg shadow-md"
              loading="lazy"
            />
          )}
        </DialogDescription>
      </DialogContent>
    </Dialog>
  );
};

export default WorksheetPreviewModal;
